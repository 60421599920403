/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * @file   src\containers\Scheduler\ManageCA\CADetails.tsx
 * @brief  CA details view and edit page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import Select, { StylesConfig } from 'react-select';
import { Row, Col, Button, Breadcrumb, useParams, moment, useState, useEffect, useNavigate, useLocation, Modal } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import EditIcon from '../../../assests/icons/Edit';
import KKISelect from '../../../components/KKISelect';
import KKICheckbox from '../../../components/KKICheckbox';
import KKIInput from '../../../components/KKIInput';
import KKIDatepicker from '../../../components/KKIDatepicker';
import KKIInputMask from '../../../components/KKIInputMask';
import { ICADetails, IColourOption, ICADetailsUpdateRequest, CAUserProfile } from '../../../interfaces/caInterface';
import { AdminRoutePath, ColorGroups, UserStatusTypes, ManagerRoutePath } from '../../../utils/enums';
import {
  DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_Z,
  FULLTIME,
  NUMBER_0,
  PARTTIME24,
  PARTTIME16,
  PARTTIME20,
  NUMBER_1,
  HTTP_STATUS_200,
  NUMBER_50,
  NUMBER_150,
  TYPE,
  NUMBER_2,
  DATE_FORMAT_MM_DD_YYYY,
  DATE_FORMAT_MM_DD_YYYY_FILTER,
} from '../../../utils/constants';
import { IObject, ISelectList } from '../../../interfaces/generalInterface';
import { validateForm } from '../../../utils/validationHelper';
import { CA_EDIT_SCHEMA } from '../../../validations/manageCASchema';
import { RootState } from '../../../store';
import { getCADetails, updateCADetails, revertAllManageCAState, resetMOTPosition } from '../../../store/actions/manageCAAction';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { formatPhoneNumber, getKeyIndicators, allowIntegerOnly } from '../../../utils/helper';
import { MessageToaster } from '../../../utils/toastUtils';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

// CA color group select options.
const colourOptions: IColourOption[] = [
  { value: ColorGroups.None, label: ColorGroups[ColorGroups.None], color: '#eee' },
  { value: ColorGroups.Red, label: ColorGroups[ColorGroups.Red], color: '#FF0000' },
  { value: ColorGroups.Yellow, label: ColorGroups[ColorGroups.Yellow], color: '#FFC400' },
  { value: ColorGroups.Blue, label: ColorGroups[ColorGroups.Blue], color: '#00CCFF' },
  { value: ColorGroups.White, label: ColorGroups[ColorGroups.White], color: '#FFF' },
];
const statusOptions: ISelectList[] = [
  { value: UserStatusTypes.Active, label: UserStatusTypes[UserStatusTypes.Active] },
  { value: UserStatusTypes.Inactive, label: UserStatusTypes[UserStatusTypes.Inactive] },
];
const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    border: '1px solid #ccc',
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles: StylesConfig<IColourOption> = {
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  input: (styles) => ({ ...styles, ...dot() }),
  option: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

// Default state for Edit CA info.
const defaultCADetails: ICADetails = {
  caId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  empId: '',
  hireDate: '',
  motPosition: NUMBER_0,
  colorGroup: NUMBER_0,
  status: NUMBER_1,
  volunteer: false,
  floatStaff: false,
  student: false,
  restrictedOt: false,
  lightDuty: false,
  partTime16: false,
  partTime20: false,
  partTime24: false,
  fullTime: false,
  assignedHours: NUMBER_0,
  actualHours: NUMBER_0,
};

const SCHCADetails = () => {
  const { caId } = useParams<{ caId: string }>();
  // Navigate object creation.
  const navigate = useNavigate();
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Create location object to access location info.
  const location = useLocation();
  const getQueryParams = (param: string): string | null => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };
  // Extract the 'type' parameter from the query string
  const typeString = getQueryParams(TYPE);
  const type = typeString !== null ? Number(typeString) : null;
  // Select profile-related state from Redux
  const { isFetchCADetailsLoading, profileInfo } = useAppSelector((state: RootState) => state.manageCA);
  // Access profile update related redux state variables.
  const {
    isUpdateCADetailsLoading,
    isUpdateCADetailsSuccess,
    updateCADetailsErrorCode,
    updateCADetailsMessage,
    isResetMOTPositionLoading,
    isResetMOTPositionSuccess,
    resetMOTPositionErrorCode,
    resetMOTPositionMessage,
  } = useAppSelector((state: RootState) => state.manageCA);

  // Initialize component state variables.
  const [isEdit, setEdit] = useState<boolean>(false);
  const [caDetails, setCADetails] = useState<ICADetails>(defaultCADetails);
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  const [resetMOTPopup, showResetMOTPopup] = useState<boolean>(false);

  // Access the passed state from the SCHManageCA component
  const { fromPage, searchQuery, sortField, sortOrder } = location.state || {};

  // Component initial loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllManageCAState());
    };
  }, []);

  // Update profile api response state side effect handling.
  useEffect(() => {
    if (isUpdateCADetailsSuccess && updateCADetailsErrorCode === HTTP_STATUS_200 && updateCADetailsMessage) {
      toast.toastSuccess(updateCADetailsMessage);
    } else if (!isUpdateCADetailsSuccess && updateCADetailsErrorCode > HTTP_STATUS_200 && updateCADetailsMessage) {
      toast.toastError(updateCADetailsMessage);
    }
    dispatch(getCADetails(Number(caId)));
  }, [isUpdateCADetailsSuccess, updateCADetailsMessage, updateCADetailsErrorCode]);

  // Reset MOT position api response state side effect handling.
  useEffect(() => {
    if (isResetMOTPositionSuccess && resetMOTPositionErrorCode === HTTP_STATUS_200 && resetMOTPositionMessage) {
      dispatch(getCADetails(Number(caId)));
      toast.toastSuccess(resetMOTPositionMessage);
    } else if (!isResetMOTPositionSuccess && resetMOTPositionErrorCode > HTTP_STATUS_200 && resetMOTPositionMessage) {
      toast.toastError(resetMOTPositionMessage);
    }
  }, [isResetMOTPositionSuccess, resetMOTPositionErrorCode, resetMOTPositionMessage]);

  // Component initial loading side effect handler.
  useEffect(() => {
    dispatch(getCADetails(Number(caId)));
  }, [dispatch]);

  // Method to map CA details.
  const mergeDetails = (profile: CAUserProfile, details: ICADetails) => {
    const mapWithFallback = (key: keyof CAUserProfile & keyof ICADetails, transform = (v: any) => v) => transform(profile[key] ?? details[key]);

    return {
      caId: mapWithFallback('caId'),
      firstName: profile.caFirstName ?? details.firstName,
      lastName: profile.caLastName ?? details.lastName,
      email: mapWithFallback('email'),
      phone: mapWithFallback('phone'),
      empId: mapWithFallback('empId'),
      hireDate: mapWithFallback('hireDate'),
      colorGroup: mapWithFallback('colorGroup'),
      motPosition: mapWithFallback('motPosition'),
      status: mapWithFallback('status', Number),
      floatStaff: mapWithFallback('floatStaff'),
      volunteer: mapWithFallback('volunteer'),
      student: mapWithFallback('student'),
      restrictedOt: mapWithFallback('restrictedOt'),
      lightDuty: mapWithFallback('lightDuty'),
      partTime16: profile.partTime16Hrs ?? details.partTime16,
      partTime20: profile.partTime20Hrs ?? details.partTime20,
      partTime24: profile.partTime24Hrs ?? details.partTime24,
      fullTime: mapWithFallback('fullTime'),
      assignedHours: NUMBER_0,
      actualHours: NUMBER_0,
    };
  };

  // Update caDetails when profileInfo is fetched
  useEffect(() => {
    if (profileInfo && Object.keys(profileInfo).length > NUMBER_0) {
      const updatedDetails = mergeDetails(profileInfo, caDetails);
      setCADetails(updatedDetails);
    }
  }, [profileInfo]);

  // Handle input field changes
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCADetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // Handle hire date changes
  const handleDateChange = (date: Date) => {
    const hireDateString = moment(new Date(date)).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_Z);
    setCADetails((prev) => ({
      ...prev,
      hireDate: hireDateString,
    }));
  };
  // Handle color group changes
  const onColorGroupChange = (val: any) => {
    setCADetails((prev) => ({
      ...prev,
      colorGroup: Number(val?.value || NUMBER_0),
    }));
  };
  // Handle status field changes
  const onStatusChange = (val: IObject) => {
    setCADetails((prev) => ({
      ...prev,
      status: Number(val?.value || NUMBER_0),
    }));
  };
  // Handle key indicator changes
  const onKeyIndicatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    switch (name) {
      case PARTTIME24:
        setCADetails((prev) => ({
          ...prev,
          [name]: checked,
          partTime16: false,
          partTime20: false,
          fullTime: false,
        }));
        break;
      case PARTTIME16:
        setCADetails((prev) => ({
          ...prev,
          [name]: checked,
          partTime20: false,
          partTime24: false,
          fullTime: false,
        }));
        break;
      case PARTTIME20:
        setCADetails((prev) => ({
          ...prev,
          [name]: checked,
          partTime16: false,
          partTime24: false,
          fullTime: false,
        }));
        break;
      case FULLTIME:
        setCADetails((prev) => ({
          ...prev,
          [name]: checked,
          partTime16: false,
          partTime24: false,
          partTime20: false,
        }));
        break;
      default:
        setCADetails((prev) => ({
          ...prev,
          [name]: checked,
        }));
        break;
    }
  };
  // Handle phone input field changes
  const onPhoneInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const inputVal = value.replace(/\D/g, '');
    setCADetails((prev) => ({
      ...prev,
      phone: inputVal,
    }));
  };
  // Navigate back to SCHManageCA with the previous state
  const handleBack = () => {
    const targetPath = type === NUMBER_1 ? AdminRoutePath.SCHManageCA : ManagerRoutePath.MNGRUserList;
    navigate(targetPath, {
      state: {
        fromPage: fromPage || 1,
        searchQuery: searchQuery || '',
        sortField: sortField || 'name',
        sortOrder: sortOrder || 'desc',
      },
    });
  };

  // Reset MOT button click handler.
  const onResetMOTClickHandler = () => {
    showResetMOTPopup(true);
  };

  // Reset MOT confirmation submission handler.
  const onResetMOTConfirmation = () => {
    if (caDetails?.caId) {
      dispatch(resetMOTPosition({ caId: caDetails?.caId || NUMBER_0 }));
      showResetMOTPopup(false);
    }
  };

  // Handle CA profile edit submit action
  const onCASubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const { firstName, lastName, hireDate, email, phone, empId } = caDetails;

    const profileSetupForm = {
      firstName,
      lastName,
      hireDate,
      email,
      phone,
      empId,
    };

    const errorResult = await validateForm(profileSetupForm, CA_EDIT_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (Object.keys(errorResult).length === 0) {
      setEdit(false);

      const caupdateRequest: ICADetailsUpdateRequest = {
        userId: caDetails.caId,
        employeeId: Number(caDetails.empId),
        firstName: caDetails.firstName,
        lastName: caDetails.lastName,
        email: caDetails.email || '',
        phone: caDetails.phone || '',
        hireDate: caDetails.hireDate,
        colorCode: caDetails.colorGroup?.toString() || '',
        floatStaff: caDetails.floatStaff,
        lightDuty: caDetails.lightDuty ?? false,
        volunteer: caDetails.volunteer,
        partTime16: caDetails.partTime16,
        partTime20: caDetails.partTime20,
        partTime24: caDetails.partTime24,
        fullTime: caDetails.fullTime,
        student: caDetails.student,
        restrictedOt: caDetails.restrictedOt,
        status: Boolean(caDetails.status),
        motPosition: caDetails.motPosition,
      };
      dispatch(updateCADetails(caupdateRequest));
    }
  };
  return (
    <div>
      <div>
        <Breadcrumb>
          {type === NUMBER_2 && <Breadcrumb.Item href={ManagerRoutePath.MNGRUserList}>{Strings.HD.ManageUsers}</Breadcrumb.Item>}
          {type === NUMBER_1 && <Breadcrumb.Item href={AdminRoutePath.SCHManageCA}>{Strings.HD.ManageCA}</Breadcrumb.Item>}
          <Breadcrumb.Item active>{isEdit ? Strings.HD.EditCADetails : Strings.HD.CADetails}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="pageheader">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{isEdit ? Strings.HD.EditCADetails : Strings.HD.CADetails}</h1>
          </Col>
          <Col xs="auto">
            {!isEdit && (
              <Button variant="outline-secondary" onClick={() => setEdit(true)}>
                <EditIcon />
                {Strings.Button.Edit}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <form onSubmit={onCASubmit}>
        <Col className="border p-3 rounded pb-0">
          <Row>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="firstName"
                label={Strings.SetupProfile.FirstName}
                name="firstName"
                type="text"
                value={caDetails?.firstName || '-'}
                onChange={onInputChange}
                alert={errorFields?.firstName || ''}
                maxLength={NUMBER_50}
                disabled={!isEdit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="lastName"
                label={Strings.SetupProfile.LastName}
                name="lastName"
                type="text"
                value={caDetails?.lastName || '-'}
                onChange={onInputChange}
                alert={errorFields?.lastName || ''}
                maxLength={NUMBER_50}
                disabled={!isEdit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="empId"
                label={Strings.SetupProfile.EmpId}
                name="empId"
                type="text"
                value={caDetails?.empId || '-'}
                onChange={onInputChange}
                alert={errorFields?.empId || ''}
                disabled={!isEdit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="email"
                label={Strings.SetupProfile.Email}
                name="email"
                type="text"
                value={caDetails?.email}
                onChange={onInputChange}
                alert={errorFields?.email || ''}
                maxLength={NUMBER_150}
                disabled={!isEdit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4 kki-select">
              <label htmlFor="colorGrp" className="form-label">
                {Strings.Label.ColorGroup}
              </label>
              <div id="colorCode" className="custom-select">
                <Select
                  name="colorCode"
                  value={colourOptions.find((item: IColourOption) => item.value === Number(caDetails?.colorGroup))}
                  options={colourOptions}
                  styles={colourStyles}
                  classNamePrefix="custom-select"
                  isSearchable={false}
                  onChange={onColorGroupChange}
                  isDisabled={!isEdit}
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIDatepicker
                id="HireDate"
                name="HireDate"
                placeholder={Strings.Label.HireDate}
                label={Strings.Label.HireDate}
                value={caDetails?.hireDate ? moment(caDetails?.hireDate).local().toDate() : moment('2024-10-01').toDate()}
                dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER}
                onChangeDatepicker={(date: any) => handleDateChange(date)}
                alert={errorFields?.hireDate || ''}
                disabled={!isEdit}
                maxDate={moment().toDate()}
              />
            </Col>
            <Col xl={6} lg={6} sm={12} className="profile-edit">
              <KKIInputMask
                id="phone"
                label={Strings.SetupProfile.MobileNumber}
                name="phone"
                type="text"
                mask="999-999-9999"
                placeholder={Strings.CsLogin.MobileNumber}
                value={caDetails?.phone && formatPhoneNumber(caDetails?.phone)}
                onChange={onPhoneInputChange}
                alert={errorFields?.phone || ''}
                disabled
                verified={false}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <Row>
                <Col className="pe-0">
                  <KKIInput
                    id="motPosition"
                    label={Strings.Label.MOTPosition}
                    name="motPosition"
                    type="text"
                    value={caDetails?.motPosition || '-'}
                    onKeyDown={allowIntegerOnly}
                    disabled
                  />
                </Col>
                <Col xs="auto" className="pt-26">
                  <Button variant="primary" type="button" onClick={() => onResetMOTClickHandler()}>
                    {Strings.Button.ResetPosition}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <label htmlFor="keyIndicators" className="fw-sb mb-3">
                Key Indicators
              </label>
              {isEdit && (
                <div id="keyIndicators">
                  <KKICheckbox
                    inline
                    id="p0"
                    key={1}
                    name="floatStaff"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.Float}
                    checked={caDetails?.floatStaff || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p1"
                    key={1}
                    name="student"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.Student}
                    checked={caDetails?.student || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p2"
                    key={2}
                    name="volunteer"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.Voluntary}
                    checked={caDetails?.volunteer || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p3"
                    key={3}
                    name="restrictedOt"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.RestrictedOT}
                    checked={caDetails?.restrictedOt || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p4"
                    key={4}
                    name="lightDuty"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.LightDuty}
                    checked={caDetails?.lightDuty || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p5"
                    key={5}
                    name="partTime16"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.PartTime16hrs}
                    checked={caDetails?.partTime16 || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p6"
                    key={6}
                    name="partTime20"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.PartTime20hrs}
                    checked={caDetails?.partTime20 || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p7"
                    key={7}
                    name="partTime24"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.PartTime24hrs}
                    checked={caDetails?.partTime24 || false}
                    onChange={onKeyIndicatorChange}
                  />
                  <KKICheckbox
                    inline
                    id="p8"
                    key={8}
                    name="fullTime"
                    checkBoxType="checkbox"
                    label={Strings.CAKeyIndicators.FullTime}
                    checked={caDetails?.fullTime || false}
                    onChange={onKeyIndicatorChange}
                  />
                </div>
              )}
              {!isEdit && (
                <div>
                  {getKeyIndicators({
                    floatStaff: caDetails?.floatStaff,
                    fullTime: caDetails?.fullTime,
                    lighDuty: caDetails?.lightDuty,
                    volunteer: caDetails?.volunteer,
                    student: caDetails?.student,
                    restrictedOt: caDetails?.restrictedOt,
                    partTime16: caDetails?.partTime16,
                    partTime20: caDetails?.partTime20,
                    partTime24: caDetails?.partTime24,
                  })}
                </div>
              )}
            </Col>

            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKISelect
                id="Status"
                label={Strings.Label.Status}
                name="Status"
                className="custom-select"
                placeholder={Strings.Label.Status}
                searchvalue={false}
                options={statusOptions}
                value={statusOptions.find((item) => item.value === caDetails?.status) || statusOptions[0]}
                onSelectChange={onStatusChange}
                disabled={!isEdit}
                alert={errorFields?.status || ''}
              />
            </Col>
          </Row>
        </Col>
        <div className="button-container mt-4">
          {!isEdit && (
            <Button variant="outline-primary" onClick={handleBack}>
              {Strings.Button.Back}
            </Button>
          )}

          {isEdit && (
            <>
              <Button variant="outline-primary" onClick={() => setEdit(false)}>
                {Strings.Button.Back}
              </Button>
              <Button variant="primary" type="submit">
                {Strings.Button.Save}
              </Button>
            </>
          )}
        </div>
      </form>
      {(isFetchCADetailsLoading || isUpdateCADetailsLoading || isResetMOTPositionLoading) && <Loader />}
      <Modal show={resetMOTPopup} onHide={() => showResetMOTPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.ResetMOTConformationText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showResetMOTPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onResetMOTConfirmation()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SCHCADetails;
