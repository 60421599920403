/**
 * @file   src\containers\Manager\ManageUser\UserDetails.tsx
 * @brief  User Details and Edit page
 * @date   October, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Row, Col, Button, Breadcrumb, useParams, useState, useEffect, useNavigate, useLocation, moment } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import EditIcon from '../../../assests/icons/Edit';
import KKISelect from '../../../components/KKISelect';
import KKIInput from '../../../components/KKIInput';
import KKIDatepicker from '../../../components/KKIDatepicker';
import KKIInputMask from '../../../components/KKIInputMask';
import { getSchedulerDetails, updateSchedulerDetails, revertAllUserState } from '../../../store/actions/userAction';
import { ISchedulerProfile, ISchedulerProfileUpdateRequest } from '../../../interfaces/userInterface';
import { IObject, ISelectList } from '../../../interfaces/generalInterface';
import { ManagerRoutePath, UserStatusTypes } from '../../../utils/enums';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_Z,
  NUMBER_0,
  NUMBER_1,
  HTTP_STATUS_200,
  NUMBER_50,
  NUMBER_150,
  USER_NAME,
  DESC,
  DATE_FORMAT_MM_DD_YYYY,
  STORAGE_USER,
  DATE_FORMAT_MM_DD_YYYY_FILTER,
} from '../../../utils/constants';
import { MessageToaster } from '../../../utils/toastUtils';
import { RootState } from '../../../store';
import { validateForm } from '../../../utils/validationHelper';
import { formatPhoneNumber, getDataFromStorage } from '../../../utils/helper';
import { CA_EDIT_SCHEMA } from '../../../validations/manageCASchema';
import Loader from '../../../components/Loader';

// Toast object creation.
const toast = new MessageToaster();

const statusOptions: ISelectList[] = [
  { value: UserStatusTypes.Active, label: UserStatusTypes[UserStatusTypes.Active] },
  { value: UserStatusTypes.Inactive, label: UserStatusTypes[UserStatusTypes.Inactive] },
];

// Default state for Edit scheduler info.
const defaultSchedulerDetails: ISchedulerProfile = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  empId: '',
  hireDate: '',
  status: NUMBER_1,
};
const MNGRUserDetails = () => {
  const { schedulerId } = useParams<{ schedulerId: string }>();
  // Navigate object creation.
  const navigate = useNavigate();
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Create location object to access location info.
  const location = useLocation();

  // Access userInfo from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  // Loggined user role
  const userId = userInfo?.userId;

  // Select profile-related state from Redux
  const { isFetchSchedulerDetailsLoading, schedulerInfo } = useAppSelector((state: RootState) => state.user);
  // Access profile update related redux state variables.
  const { isUpdateSchedulerDetailsLoading, isUpdateSchedulerDetailsSuccess, updateSchedulerDetailsErrorCode, updateSchedulerDetailsMessage } = useAppSelector(
    (state: RootState) => state.user,
  );

  // Initialize component state variables.
  const [isEdit, setEdit] = useState<boolean>(false);
  const [schedulerDetails, setSchedulerDetails] = useState<ISchedulerProfile>(defaultSchedulerDetails);
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  // Access the passed state from the MNGRUserList component
  const { fromPage, searchQuery, sortField, sortOrder } = location.state || {};
  // Component initial loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllUserState());
    };
  }, []);

  // Update profile api response state side effect handling.
  useEffect(() => {
    if (isUpdateSchedulerDetailsSuccess && updateSchedulerDetailsErrorCode === HTTP_STATUS_200 && updateSchedulerDetailsMessage) {
      toast.toastSuccess(updateSchedulerDetailsMessage);
    } else if (!isUpdateSchedulerDetailsSuccess && updateSchedulerDetailsErrorCode > HTTP_STATUS_200 && updateSchedulerDetailsMessage) {
      toast.toastError(updateSchedulerDetailsMessage);
    }
    dispatch(getSchedulerDetails(Number(schedulerId)));
  }, [isUpdateSchedulerDetailsSuccess, updateSchedulerDetailsMessage, updateSchedulerDetailsErrorCode]);

  useEffect(() => {
    dispatch(getSchedulerDetails(Number(schedulerId)));
  }, [dispatch]);

  // Update schedulerDetails when schedulerInfo is fetched
  useEffect(() => {
    if (schedulerInfo && Object.keys(schedulerInfo).length > NUMBER_0) {
      const updatedDetails = {
        userId: schedulerInfo.userId ?? schedulerDetails.userId,
        firstName: schedulerInfo.firstName ?? schedulerDetails.firstName,
        lastName: schedulerInfo.lastName ?? schedulerDetails.lastName,
        email: schedulerInfo.email ?? schedulerDetails.email,
        phone: schedulerInfo.phone ?? schedulerDetails.phone,
        empId: schedulerInfo.empId ?? schedulerDetails.empId,
        hireDate: schedulerInfo.hireDate ?? schedulerDetails.hireDate,
        status: Number(schedulerInfo.status ?? schedulerDetails.status),
      };
      setSchedulerDetails(updatedDetails);
    }
  }, [schedulerInfo]);

  // Handle input field changes
  const onInputChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const { value } = event.target;
    setSchedulerDetails((prev) => ({ ...prev, [field]: value }));
    const profileSetupForm = { [field]: value };
    const errorResult = await validateForm(profileSetupForm, CA_EDIT_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Handle hire date changes
  const handleDateChange = (date: Date) => {
    const hireDateString = moment(new Date(date)).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_Z);
    setSchedulerDetails((prev) => ({
      ...prev,
      hireDate: hireDateString,
    }));
  };
  // Handle status field changes
  const onStatusChange = (val: IObject) => {
    setSchedulerDetails((prev) => ({
      ...prev,
      status: Number(val?.value || NUMBER_0),
    }));
  };

  // Handle phone input field changes
  const onPhoneInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const inputVal = value.replace(/\D/g, '');
    setSchedulerDetails((prev) => ({
      ...prev,
      phone: inputVal,
    }));
  };
  // Navigate back to MNGRUserList with the previous state
  const handleBack = () => {
    navigate(ManagerRoutePath.MNGRUserList, {
      state: {
        fromPage: fromPage || 1,
        searchQuery: searchQuery || '',
        sortField: sortField || USER_NAME,
        sortOrder: sortOrder || DESC,
      },
    });
  };
  // Handle scheduler profile edit submit action
  const onCASubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const { firstName, lastName, hireDate, email, phone, empId } = schedulerDetails;

    const profileSetupForm = {
      firstName,
      lastName,
      hireDate,
      email,
      phone,
      empId,
    };

    const errorResult = await validateForm(profileSetupForm, CA_EDIT_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (Object.keys(errorResult).length === 0) {
      setEdit(false);

      const updateRequest: ISchedulerProfileUpdateRequest = {
        userId: schedulerDetails.userId,
        empId: Number(schedulerDetails.empId),
        firstName: schedulerDetails.firstName,
        lastName: schedulerDetails.lastName,
        email: schedulerDetails.email || '',
        phone: schedulerDetails.phone || '',
        hireDate: schedulerDetails.hireDate,
        status: Boolean(schedulerDetails.status),
      };
      dispatch(updateSchedulerDetails(updateRequest));
    }
  };

  // Render user action buttons.
  const renderActionButtons = () => (
    <div className="button-container mt-4">
      {!isEdit && (
        <Button variant="outline-primary" onClick={handleBack}>
          {Strings.Button.Back}
        </Button>
      )}

      {isEdit && (
        <>
          <Button variant="outline-primary" onClick={() => setEdit(false)}>
            {Strings.Button.Back}
          </Button>
          <Button variant="primary" type="submit">
            {Strings.Button.Save}
          </Button>
        </>
      )}
    </div>
  );

  // Set input values based on view/edit type.
  const setValueBasedOnActionType = (val: string) => {
    return isEdit ? val || '' : val || '-';
  };

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href={ManagerRoutePath.MNGRUserList}>{Strings.HD.ManageUsers}</Breadcrumb.Item>
          <Breadcrumb.Item active>{isEdit ? Strings.HD.EditSchedulerUserDetails : Strings.HD.SchedulerUserDetails}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="pageheader">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{isEdit ? Strings.HD.EditSchedulerUserDetails : Strings.HD.SchedulerUserDetails}</h1>
          </Col>
          {schedulerDetails?.userId !== userId && (
            <Col xs="auto">
              <Button variant="outline-secondary" onClick={() => setEdit(true)}>
                <EditIcon />
                Edit
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <form onSubmit={onCASubmit}>
        <Col className="border p-3 rounded pb-0">
          <Row>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="FirstName"
                label={Strings.SetupProfile.FirstName}
                name="firstName"
                type="text"
                value={setValueBasedOnActionType(schedulerDetails?.firstName)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, 'firstName')}
                alert={errorFields?.firstName || ''}
                maxLength={NUMBER_50}
                disabled={!isEdit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="LastName"
                label={Strings.SetupProfile.LastName}
                name="lastName"
                type="text"
                value={setValueBasedOnActionType(schedulerDetails?.lastName)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, 'lastName')}
                alert={errorFields?.lastName || ''}
                maxLength={NUMBER_50}
                disabled={!isEdit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="EmployeeID"
                label={Strings.SetupProfile.EmpId}
                name="EmployeeID"
                type="text"
                value={setValueBasedOnActionType(schedulerDetails?.empId)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, 'empId')}
                alert={errorFields?.empId || ''}
                disabled={!isEdit}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIInput
                id="Email"
                label={Strings.SetupProfile.Email}
                name="Email"
                type="text"
                value={setValueBasedOnActionType(schedulerDetails?.email)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, 'email')}
                alert={errorFields?.email || ''}
                maxLength={NUMBER_150}
                disabled={!isEdit}
              />
            </Col>
            <Col xl={6} lg={6} sm={12} className="profile-edit">
              <KKIInputMask
                id="mobnumber"
                label={Strings.SetupProfile.MobileNumber}
                name="mobile"
                type="text"
                mask="999-999-9999"
                placeholder={Strings.CsLogin.MobileNumber}
                value={schedulerDetails?.phone && formatPhoneNumber(schedulerDetails?.phone)}
                onChange={onPhoneInputChange}
                alert={errorFields?.phone || ''}
                disabled
                verified={false}
              />
            </Col>
            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKIDatepicker
                id="HireDate"
                name="hireDate"
                placeholder={Strings.Label.HireDate}
                label={Strings.Label.HireDate}
                value={schedulerDetails?.hireDate ? moment(schedulerDetails?.hireDate).local().toDate() : moment('2024-10-01').toDate()}
                dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER}
                onChangeDatepicker={(date: any) => handleDateChange(date)}
                alert={errorFields?.hireDate || ''}
                disabled={!isEdit}
                maxDate={moment().toDate()}
              />
            </Col>

            <Col lg={6} md={6} sm={12} className="mb-4">
              <KKISelect
                id="Status"
                label={Strings.Label.Status}
                name="Status"
                className="custom-select"
                placeholder={Strings.Label.Status}
                searchvalue={false}
                options={statusOptions}
                value={statusOptions.find((item) => item.value === schedulerDetails?.status) || statusOptions[0]}
                onSelectChange={onStatusChange}
                disabled={!isEdit}
                alert={errorFields?.status || ''}
              />
            </Col>
          </Row>
        </Col>
        {renderActionButtons()}
      </form>
      {(isFetchSchedulerDetailsLoading || isUpdateSchedulerDetailsLoading) && <Loader />}
    </div>
  );
};
export default MNGRUserDetails;
