/**
 * @file   src\containers\Scheduler\Schedule\AssignCA.tsx
 * @brief  Assign CA page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { isShiftDateISHoliday, revertAllCommonState } from '../../../store/actions/commonAction';
import { Badge, Row, Col, Breadcrumb, useState, Button, useParams, useNavigate, useEffect, Modal, moment, Form } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import AssignCACard from '../../../components/AssignCACard';
import EmployeeCard from '../../../components/EmployeeCard';
import KKISearch from '../../../components/KKISearch';
import KKISelect from '../../../components/KKISelect';
import { IAssignedOrUnassigedCAListRequest, ICA } from '../../../interfaces/scheduleInterface';
import { ColorGroups, ScheduleTabs, AdminRoutePath, NavigationToEmployeeCardFrom, AssignCAType, CAListTypes } from '../../../utils/enums';
import { ALL_TEXT, CURRENT_DATE_FORMAT_WITH_OFFSET, FULL_CA_LIST, HTTP_STATUS_200, NUMBER_0, NUMBER_2, PREFERED_CA_LIST } from '../../../utils/constants';
import { MessageToaster } from '../../../utils/toastUtils';
import { AT_LEAST_ONE_EMPLOYEE_REQUIRED } from '../../../messages/validationMessages';
import { IObject } from '../../../interfaces/generalInterface';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { assignCA, getShiftDetails, revertAllScheduleState } from '../../../store/actions/manageCAScheduleAction';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader';
import { getAssignedOrUnassignedCAList, revertAllAdminScheduleState } from '../../../store/actions/manageAdminScheduleAction';
import { ICADetails } from '../../../interfaces/caInterface';
import KKICheckbox from '../../../components/KKICheckbox';
import { IOverlap } from '../../../interfaces/commonInterface';
import { checkShiftOverstaffed, getCallOnCAList } from '../../../store/actions/manageCallOutShiftAction';

// Toast object creation.
const toast = new MessageToaster();
const AssignCA = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Create route param object to access the route parameters.
  const params = useParams();

  // Navigate object creation.
  const navigate = useNavigate();

  // Access redux state variables.
  const { isShiftDetailsLoading, shiftDetails, isAssignShiftLoading, isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage } = useAppSelector(
    (state: RootState) => state.manageCASchedule,
  );
  const { isCaListSuccess, caListErrorCode, isCaListLoading, caList } = useAppSelector((state: RootState) => state.manageAdminSchedule);
  const { isShiftHolidayLoading, isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, overlapResponse } = useAppSelector((state: RootState) => state.common);
  const {
    isFetchCallOnCALoading,
    isFetchCallOnCASuccess,
    fetchCallOnCAErrorCode,
    callOnCAList,
    isCheckOverStaffedLoading,
    isCheckOverStaffedSuccess,
    checkOverStaffedErrorCode,
    checkOverStaffedMessage,
  } = useAppSelector((state: RootState) => state.manageCallOut);

  // Initialize component stat variables.
  const [selectedEmployees, setSelectedEmployees] = useState<ICA[]>([]);
  const [selectedEmployeesWithOverlap, setSelectedEmployeesWithOverlap] = useState<ICA[]>([]);
  const [selectedColorGroup, setSelectedColorGroup] = useState<IObject>({ label: 'All', value: '0' });
  const [assignedCAList, setAssignedCAList] = useState<ICADetails[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [assignButtonPopup, showAssignButtonPopup] = useState<boolean>(false);
  const [holidayConfirmPopup, showHolidayConfirmPopup] = useState<boolean>(false);
  const [overlapPopup, showOverlapPopup] = useState<boolean>(false);
  const [overlapCAs, setOverlapCAs] = useState<ICA[]>([]);
  const [overStaffedMessage, setOverStaffedMessage] = useState<string>('');
  const [selectedCAListType, setSelectedCAListType] = useState<IObject>({ label: PREFERED_CA_LIST, value: CAListTypes.PREFERRED_CA_LIST.toString() });

  /* Access and set the location url parameters. */
  const shiftId: number = params.shiftId ? Number(params.shiftId) : 0;
  const type: number = params.shiftId ? Number(params.type) : 0;
  const caIdFromUrl: number = params.caId ? Number(params.caId) : 0;

  // Method to fetch shift details.
  const getShiftInfo = () => {
    const requestParams =
      type === ScheduleTabs.MySchedule
        ? { userId: caIdFromUrl, shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }
        : { shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) };
    dispatch(getShiftDetails(requestParams));
  };

  // Component initial loading.
  useEffect(() => {
    getShiftInfo();
    return () => {
      dispatch(revertAllAdminScheduleState());
      dispatch(revertAllScheduleState());
    };
  }, [isAssignShiftSuccess && assignShiftErrorCode === HTTP_STATUS_200]);

  // Method to get assigned CA list
  const getCAList = () => {
    if (selectedCAListType?.value && Number(selectedCAListType?.value) === Number(CAListTypes.PREFERRED_CA_LIST)) {
      // Check the shift is overstaffed or not.
      if (shiftId) {
        dispatch(checkShiftOverstaffed({ shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));
      }
    } else {
      if (shiftDetails?.shiftId) {
        const requestParams: IAssignedOrUnassigedCAListRequest = {
          shiftId: shiftDetails?.shiftId,
          type: NUMBER_2,
          keyword: searchText,
          colorGroup: Number(selectedColorGroup.value),
        };
        dispatch(getAssignedOrUnassignedCAList(requestParams));
      }
    }
  };

  // get Ca list api calling
  useEffect(() => {
    getCAList(); // Call the assigned CA List Api
  }, [shiftDetails, searchText, selectedColorGroup, selectedCAListType]);

  // Check overstaffed shift api response state change.
  useEffect(() => {
    if (isCheckOverStaffedSuccess && checkOverStaffedErrorCode === HTTP_STATUS_200 && checkOverStaffedMessage) {
      // Fetch callout CA list if request parameters are valid.
      if (shiftId > NUMBER_0 && shiftDetails?.startDate && shiftDetails?.shiftFromTime) {
        setOverStaffedMessage('');
        dispatch(
          getCallOnCAList({
            shiftId,
            shiftDate: shiftDetails?.startDate !== '-' ? shiftDetails?.startDate : '',
            shiftFromTime: shiftDetails?.shiftFromTime !== '-' ? shiftDetails?.shiftFromTime : '',
            currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
            keyword: searchText,
            fromShiftAssign: true,
            colorGroup: Number(selectedColorGroup.value),
          }),
        );
      }
    } else if (!isCheckOverStaffedSuccess && checkOverStaffedErrorCode > HTTP_STATUS_200 && checkOverStaffedMessage) {
      setAssignedCAList([]);
      setOverStaffedMessage(checkOverStaffedMessage);
    }
  }, [isCheckOverStaffedSuccess, checkOverStaffedErrorCode, checkOverStaffedMessage]);

  // Assigend CA state change side effect handling.
  useEffect(() => {
    if (isCaListSuccess && caListErrorCode === HTTP_STATUS_200) {
      setAssignedCAList(caList?.caList || []);
    } else if (!isCaListSuccess && caListErrorCode > HTTP_STATUS_200) {
      setAssignedCAList([]);
    }
  }, [isCaListSuccess, caListErrorCode, caList]);

  // Preferred CA list api response change.
  useEffect(() => {
    if (isFetchCallOnCASuccess && fetchCallOnCAErrorCode === HTTP_STATUS_200) {
      setAssignedCAList(callOnCAList || []);
    } else if (!isFetchCallOnCASuccess && fetchCallOnCAErrorCode > HTTP_STATUS_200) {
      setAssignedCAList([]);
    }
  }, [isFetchCallOnCASuccess, fetchCallOnCAErrorCode, callOnCAList]);

  // Assign CA response state change.
  useEffect(() => {
    if (isAssignShiftSuccess && assignShiftErrorCode === HTTP_STATUS_200 && assignShiftMessage) {
      setSelectedEmployees([]);
      setSelectedEmployeesWithOverlap([]);
      toast.toastSuccess(assignShiftMessage);
      getShiftInfo();
      getCAList();
    } else if (!isAssignShiftSuccess && assignShiftErrorCode > HTTP_STATUS_200 && assignShiftMessage) {
      setSelectedEmployees([]);
      setSelectedEmployeesWithOverlap([]);
      getShiftInfo();
      getCAList();
      toast.toastError(assignShiftMessage);
    }
  }, [isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage]);

  // Shift holiday checking api response state change.
  useEffect(() => {
    if (isShiftHolidaySuccess && shiftHolidayErrorCode === HTTP_STATUS_200 && shiftHolidayMessage) {
      const filteredCAs =
        overlapResponse &&
        overlapResponse.overlap &&
        overlapResponse.overlap
          .filter((res: IOverlap) => res.isOverlap === true)
          ?.map((cas: IOverlap): ICA => ({ caId: cas.caId, caName: cas.caName, ColorCode: cas.colorCode, IsLD: cas.isLD, OnCall: cas.onCall }));
      setOverlapCAs(filteredCAs || []);
      if (overlapResponse && overlapResponse.holiday && overlapResponse.holiday.isHoliday) {
        showHolidayConfirmPopup(true);
      } else if (overlapResponse && filteredCAs && filteredCAs.length > NUMBER_0) {
        showOverlapPopup(true);
      } else {
        showAssignButtonPopup(true);
      }
      dispatch(revertAllCommonState());
    } else if (!isShiftHolidaySuccess && shiftHolidayErrorCode > HTTP_STATUS_200 && shiftHolidayMessage) {
      toast.toastError(shiftHolidayMessage);
      dispatch(revertAllCommonState());
    }
  }, [isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, overlapResponse]);

  // Handles the checkbox change event.
  const handleCheckboxChange = (isChecked: boolean, caDetails: ICADetails) => {
    const { caId, colorGroup, lightDuty } = caDetails;
    if (isChecked) {
      setSelectedEmployees((prevSelected) => [
        ...prevSelected,
        {
          caId,
          ColorCode: Number(colorGroup),
          OnCall: false,
          IsLD: lightDuty ?? false,
        },
      ]);
      setSelectedEmployeesWithOverlap((prevSelected) => [
        ...prevSelected,
        {
          caId,
          ColorCode: Number(colorGroup),
          OnCall: false,
          IsLD: lightDuty ?? false,
        },
      ]);
    } else {
      setSelectedEmployees((prevSelected) => prevSelected.filter((entry) => entry.caId !== caId));
      setSelectedEmployeesWithOverlap((prevSelected) => prevSelected.filter((entry) => entry.caId !== caId));
    }
  };

  //  Handles the "Assign" button click event. Shows an error if no employees are selected.
  const handleAssignClick = (): void => {
    if (selectedEmployees.length === 0) {
      toast.toastError(AT_LEAST_ONE_EMPLOYEE_REQUIRED);
      return;
    }
    if (shiftDetails?.startDate) {
      const caIds = selectedEmployees.map((emp: ICA) => emp.caId);
      dispatch(isShiftDateISHoliday({ holidayDate: shiftDetails?.startDate?.split('T')[0] || '', shiftId: shiftDetails?.shiftId || NUMBER_0, caId: caIds }));
    } else {
      showAssignButtonPopup(true);
    }
  };

  // Method to assign CAs to the shift.
  const assignCAs = (caInfo: ICA[]) => {
    if (shiftDetails) {
      const assignRequest = {
        Cas: caInfo,
        shiftId: shiftDetails?.shiftId,
        shiftStart: shiftDetails?.shiftFromTime,
        currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
        overrideColorCode: true,
      };
      dispatch(assignCA(assignRequest));
      showAssignButtonPopup(false);
      showHolidayConfirmPopup(false);
      showOverlapPopup(false);
    }
  };

  // Handles the assign confirmation
  const onAssignConfirm = (assignmentType: number) => {
    if (assignmentType === AssignCAType.HOLIDAY) {
      if (overlapCAs && overlapCAs.length > NUMBER_0) {
        showHolidayConfirmPopup(false);
        showOverlapPopup(true);
      } else {
        assignCAs(selectedEmployees);
      }
    } else {
      assignCAs(selectedEmployees);
    }
  };

  // Handles the onChange event for the color group dropdown.
  const handleColorGroupChange = (val: IObject) => {
    setSelectedColorGroup(val);
  };

  // Handles the onChange event for the CA list type change.
  const handleCAListTypeChange = (val: IObject) => {
    setSelectedCAListType(val);
  };

  // CA search input field button click event handler.
  const onCASearch = (val: string) => {
    setSearchText(val);
  };

  // Backbutton navigation
  const BackButtonNavigation = () => {
    navigate(
      caIdFromUrl
        ? AdminRoutePath.SCHShiftDetailsByCAId.replace(':shiftId', shiftId.toString())
            .replace(':type', ScheduleTabs.MySchedule.toString())
            .replace(':caId', caIdFromUrl?.toString())
        : AdminRoutePath.SCHShiftDetails.replace(':shiftId', shiftId.toString()).replace(':type', Number(type).toString()),
    );
  };

  // Close overlap popup event handler.
  const onOverlapPopupClose = () => showOverlapPopup(false);

  // Overlapped CA selection assignment handler.
  const onOverlapAssignmentHandler = () => assignCAs(selectedEmployeesWithOverlap);

  // Handler for overlap checkbox change
  const handleOverlapCheckboxChange = (overlapCaDetails: ICA) => {
    setSelectedEmployeesWithOverlap((prev) => {
      if (prev.some((cas: ICA) => cas.caId === overlapCaDetails.caId)) {
        // If caId is already selected, remove it from the array
        return prev.filter((ca: ICA) => ca.caId !== overlapCaDetails.caId);
      } else {
        // If caId is not selected, add it to the array
        return [...prev, overlapCaDetails];
      }
    });
  };

  // Color group select options.
  const colorGroup = [
    { label: ALL_TEXT, value: NUMBER_0 },
    { label: ColorGroups[ColorGroups.Yellow], value: ColorGroups.Yellow },
    { label: ColorGroups[ColorGroups.Blue], value: ColorGroups.Blue },
    { label: ColorGroups[ColorGroups.Red], value: ColorGroups.Red },
    { label: ColorGroups[ColorGroups.White], value: ColorGroups.White },
  ];

  // CA list select type options.
  const caListingType = [
    { label: PREFERED_CA_LIST, value: CAListTypes.PREFERRED_CA_LIST },
    { label: FULL_CA_LIST, value: CAListTypes.ALL_CA_LIST },
  ];

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href={AdminRoutePath.ManageSchedule}>{Strings.HD.ManageSchedules}</Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => {
              BackButtonNavigation();
            }}
          >
            {Strings.Shift.ShiftDetails.Header}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{Strings.HD.AssignCA}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="pageheader">
        <Row className="align-items-center">
          <Col>
            <h1>{Strings.HD.AssignCA}</h1>
          </Col>
        </Row>
      </div>
      <AssignCACard shiftDetails={shiftDetails} isLd={false} />
      <div className="filter-main mt-3">
        <Row className="justify-content-between">
          <Col>
            <Row>
              <Col xs="auto" className="d-flex">
                <Badge bg="secondary" className="badge-secondary">
                  {Strings.Label.Selected}: <span className="ps-1"> {selectedEmployees.length}</span>
                </Badge>
              </Col>
              <Col xs>
                <KKISearch placeholder={Strings.Input.SearchbyNameEmployeeID} onSearch={(param: string) => onCASearch(param)} />
              </Col>
              <Col lg={3} md={4} xs="auto" className="z-2">
                <Row className="d-flex align-items-center">
                  <Col xs="auto" className="pe-0">
                    <span>{Strings.Label.ColorGroup}</span>
                  </Col>
                  <Col className="pe-0">
                    <KKISelect
                      id="colorGroupSelect"
                      name="colorGroupSelect"
                      className="custom-select"
                      placeholder={Strings.Sort.Select}
                      searchvalue={false}
                      options={colorGroup}
                      value={selectedColorGroup}
                      onSelectChange={handleColorGroupChange}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <div className="w-200 drop-265">
                  <KKISelect
                    id="caListType"
                    name="caListType"
                    className="custom-select"
                    placeholder={Strings.Sort.Select}
                    searchvalue={false}
                    options={caListingType}
                    value={selectedCAListType}
                    onSelectChange={handleCAListTypeChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg="auto" md={12} className="button-container-right m-md-16 text-end">
            <Button
              variant="outline-primary"
              type="submit"
              onClick={() => {
                BackButtonNavigation();
              }}
            >
              {Strings.Button.Back}
            </Button>

            <Button variant="primary" type="submit" onClick={handleAssignClick}>
              {Strings.Shift.Button.Assign}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        {assignedCAList &&
          assignedCAList?.length > NUMBER_0 &&
          assignedCAList.map((caData: ICADetails) => (
            <Col xl={6} lg={6} md={12} key={caData.caId}>
              <EmployeeCard
                caDetails={caData}
                shiftDetails={shiftDetails}
                isFrom={NavigationToEmployeeCardFrom.CA_Assign_Page}
                onCheckboxChange={handleCheckboxChange}
                selectedEmployees={selectedEmployees}
                preferredType={Number(selectedCAListType?.value || null)}
              />
            </Col>
          ))}
        {!overStaffedMessage && (!assignedCAList || (assignedCAList && assignedCAList?.length === NUMBER_0)) && (
          <div className="mt-5 text-center w-100">{Strings.Text.NoCAFound}</div>
        )}
        {overStaffedMessage && <div className="mt-5 text-center w-100">{overStaffedMessage}</div>}
      </Row>

      <Modal show={assignButtonPopup} onHide={() => showAssignButtonPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.AssignCAsPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showAssignButtonPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onAssignConfirm(AssignCAType.ASSIGNMENT)} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={holidayConfirmPopup} onHide={() => showHolidayConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.HolidayConfirmPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showHolidayConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onAssignConfirm(AssignCAType.HOLIDAY)} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={overlapPopup} onHide={() => onOverlapPopupClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.OverlapPop.Heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Strings.OverlapPop.Text1}</p>
          {overlapCAs.map((overlapItem: ICA) => (
            <div className="mb-2 fw-sb" key={overlapItem.caId}>
              <KKICheckbox
                id="overlap"
                name="overlap"
                checkBoxType="checkbox"
                label={overlapItem.caName || ''}
                checked={selectedEmployeesWithOverlap.some((cas: ICA) => cas.caId === overlapItem.caId)}
                onChange={() => handleOverlapCheckboxChange(overlapItem)}
              />
            </div>
          ))}
          <p>
            <span className="fw-sb">{Strings.OverlapPop.Note}</span> {Strings.OverlapPop.NoteText}
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button variant="secondary" onClick={() => onOverlapPopupClose()} className="w-auto">
            {Strings.Button.Cancel}
          </Button>
          <Button variant="primary" onClick={() => onOverlapAssignmentHandler()}>
            {Strings.Button.Proceed}
          </Button>
        </Modal.Footer>
      </Modal>
      {(isShiftDetailsLoading || isCaListLoading || isAssignShiftLoading || isShiftHolidayLoading || isFetchCallOnCALoading || isCheckOverStaffedLoading) && <Loader />}
    </div>
  );
};
export default AssignCA;
