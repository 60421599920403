/**
 * @file   src\containers\Scheduler\Schedule\ViewSchedules.tsx
 * @brief  View schedules page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import {
  Button,
  Row,
  Col,
  Breadcrumb,
  useState,
  ButtonGroup,
  Table,
  moment,
  useEffect,
  Modal,
  useParams,
  useLocation,
  useNavigate,
  useRef,
} from '../../../components/ThirdPartyComponents';
import ScheduleCard from '../../../components/SchedulerCard';
import KKIDatepicker from '../../../components/KKIDatepicker';
import Arrow from '../../../assests/icons/DownArrow';
import Strings from '../../../assests/strings/Strings.json';
import { convertFloatToTime, getCalculatedDateTime, getDataFromStorage, getDatesBetween, getKeyIndicators, setStorageData } from '../../../utils/helper';
import {
  CURRENT_DATE_FORMAT_WITH_OFFSET,
  DATE_FORMAT_MM_DD_YYYY_FILTER,
  DATE_FORMAT_YYYY_MM_DD,
  HTTP_STATUS_200,
  NIGHT_SHIFTS,
  NUMBER_0,
  NUMBER_1,
  NUMBER_50,
  NUMBER_6,
  NUMBER_7,
  NUMBER_EMPTY,
  OTHER_REASON_LABEL,
  STORAGE_VIEW_SCHEDULE_FILTER,
} from '../../../utils/constants';
import { ColorGroups, DateTimeCalculatedTypes, MyScheduleActions, OperationTypes, PrevNextTypes, AdminRoutePath, ShiftStatus } from '../../../utils/enums';
import { IKeyIndicators } from '../../../interfaces/caInterface';
import {
  ICAScheduleItems,
  IScheduleItem,
  IRosterScheduleView,
  IGetCAShiftsAndLeaveDetailsRequest,
  IUnassignCARequest,
  ICallOutShiftRequest,
} from '../../../interfaces/scheduleInterface';
import { ILeaveDetails } from '../../../interfaces/leaveInterface';
import LeaveDetails from '../../../components/LeaveDetailsCard';
import { RootState } from '../../../store';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCalloutReasons, getSchedulesAndLeaveDetails, revertAllCommonState } from '../../../store/actions/commonAction';
import { MessageToaster } from '../../../utils/toastUtils';
import Loader from '../../../components/Loader';
import { callOutShift, revertAllScheduleState, unassignCA } from '../../../store/actions/manageCAScheduleAction';
import { publishShift, revertAllAdminScheduleState, unpublishShift } from '../../../store/actions/manageAdminScheduleAction';
import ScheduleFilter from '../../../components/commonComponents/ViewScheduleFilter';
import KKIInput from '../../../components/KKIInput';
import KKISelect from '../../../components/KKISelect';
import { IObject } from '../../../interfaces/generalInterface';
import { ICalloutReasons } from '../../../interfaces/commonInterface';
import { validateForm } from '../../../utils/validationHelper';
import { CALLOUT_REASON_SCHEMA } from '../../../validations/manageScheduleSchema';
import KKISearch from '../../../components/KKISearch';

// Toast object creation.
const toast = new MessageToaster();

const ViewSchedules = () => {
  // Create route param object to access the route parameters.
  const params = useParams();

  // Create location object to access location info.
  const location = useLocation();

  // Navigate object creation.
  const navigate = useNavigate();

  // Create a reference for table container.
  const tableRef = useRef<any>(null);

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  /* Access and set the location url parameters. */
  const caUserId: number = params.caId ? Number(params.caId) : 0;

  // Get the filter data from session
  const filterDataFromStorage = getDataFromStorage(STORAGE_VIEW_SCHEDULE_FILTER) || [];

  // Access redux state variables.
  const {
    isUserScheduleAndLeaveDetailsLoading,
    isUserScheduleAndLeaveDetailsSuccess,
    userScheduleAndLeaveDetails,
    userScheduleAndLeaveDetailsErrorCode,
    userScheduleAndLeaveDetailsMessage,
    isFetchCalloutReasonSuccess,
    fetchCalloutReasonErrorCode,
    fetchCalloutReasonMessage,
    callOutReasons,
  } = useAppSelector((state: RootState) => state.common);
  const {
    isCallOutShiftLoading,
    isCallOutShiftSuccess,
    callOutShiftErrorCode,
    callOutShiftMessage,
    isUnassignShiftLoading,
    isUnassignShiftSuccess,
    unassignShiftErrorCode,
    unassignShiftMessage,
  } = useAppSelector((state: RootState) => state.manageCASchedule);
  const {
    isPublishShiftLoading,
    isPublishShiftSuccess,
    publishShiftErrorCode,
    publishShiftMessage,
    isUnPublishShiftLoading,
    isUnPublishShiftSuccess,
    unPublishShiftErrorCode,
    unPublishShiftMessage,
  } = useAppSelector((state: RootState) => state.manageAdminSchedule);

  // Initialize component state variables.
  const [startDateSelected, setStartDateSelected] = useState<Date | null>(!caUserId && filterDataFromStorage?.startDate ? filterDataFromStorage?.startDate : moment().toDate());
  const [endDateSelected, setEndDateSelected] = useState<Date | null>(
    !caUserId && filterDataFromStorage?.endDate
      ? filterDataFromStorage?.endDate
      : getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_6, moment().toDate()),
  );
  const [selectedPublishStatus, setSelectedPublishStatus] = useState<number[]>(
    !caUserId && filterDataFromStorage?.published ? filterDataFromStorage.published : [ShiftStatus.Published, ShiftStatus.Draft],
  );
  const [indicators, setIndicators] = useState<IKeyIndicators>(
    !caUserId && filterDataFromStorage?.indicators
      ? filterDataFromStorage.indicators
      : {
          floatStaff: true,
          student: true,
          volunteer: true,
          lightDuty: true,
          restrictedOT: true,
          partTime16: true,
          partTime20: true,
          partTime24: true,
          fullTime: true,
        },
  );
  const [scheduleList, setScheduleList] = useState<any>(null);
  const [scheduleDates, setScheduleDates] = useState<string[]>(
    getDatesBetween(moment(startDateSelected).format(DATE_FORMAT_YYYY_MM_DD), moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD)),
  );
  const [leaveDetailsPopup, showLeaveDetailsPopup] = useState<boolean>(false);
  const [unAssignPopup, showUnAssignPopup] = useState<boolean>(false);
  const [callOutPopup, showCallOutPopup] = useState<boolean>(false);
  const [publishPopup, showPublishPopup] = useState<boolean>(false);
  const [unPublishPopup, showUnPublishPopup] = useState<boolean>(false);
  const [caLeaveDetails, setCALeaveDetails] = useState<ILeaveDetails | null>(null);
  const [selectedCAName, setSelectedCAName] = useState<string>('');
  const [unAssignRequest, setUnAssignRequest] = useState<IUnassignCARequest | null>(null);
  const [callOutShiftRequest, setCallOutShiftRequest] = useState<ICallOutShiftRequest | null>(null);
  const [publishShiftId, setPublishShiftId] = useState<number>(NUMBER_0);
  const [callOutReasonList, setCallOutReasonList] = useState<IObject[]>([]);
  const [selectedReason, setSelectedReason] = useState<IObject | null>(null);
  const [callOutReasonText, setCallOutReasonText] = useState<string>('');
  const [unPublishShiftId, setUnPublishShiftId] = useState<number>(NUMBER_0);
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  const [searchText, setSearchText] = useState<string>(!caUserId && filterDataFromStorage?.searchText ? filterDataFromStorage?.searchText : '');

  // Method to fetch schedules api.
  const fetchSchedules = () => {
    const scheduleRequest: IGetCAShiftsAndLeaveDetailsRequest = {
      startDate: moment(startDateSelected).subtract(NUMBER_1, 'days').format(DATE_FORMAT_YYYY_MM_DD),
      endDate: moment(endDateSelected).format(DATE_FORMAT_YYYY_MM_DD),
      published: selectedPublishStatus,
      floatStaff: Number(indicators.floatStaff),
      volunteer: Number(indicators.volunteer),
      lightDuty: Number(indicators.lightDuty),
      restrictedOt: Number(indicators.restrictedOT),
      student: Number(indicators.student),
      partTime24: Number(indicators.partTime24),
      partTime16: Number(indicators.partTime16),
      partTime20: Number(indicators.partTime20),
      fullTime: Number(indicators.fullTime),
      includeLeaves: NUMBER_1,
      keyword: searchText,
    };
    if (caUserId > 0) {
      scheduleRequest.userId = caUserId;
    }

    //  Set the filter details to the session storage
    const updatedDetails = {
      startDate: startDateSelected,
      endDate: endDateSelected,
      published: selectedPublishStatus,
      searchText,
      indicators,
    };

    setStorageData(STORAGE_VIEW_SCHEDULE_FILTER, JSON.stringify(updatedDetails));

    dispatch(getSchedulesAndLeaveDetails(scheduleRequest));
  };

  // State change side effect handler to fetch schedule api.
  useEffect(() => {
    fetchSchedules();
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [startDateSelected, selectedPublishStatus, indicators, searchText]);

  // Unassign shift api response state change.
  useEffect(() => {
    if (isUnassignShiftSuccess && unassignShiftErrorCode === HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastSuccess(unassignShiftMessage);
      dispatch(revertAllScheduleState());
      fetchSchedules();
    } else if (!isUnassignShiftSuccess && unassignShiftErrorCode > HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastError(unassignShiftMessage);
      dispatch(revertAllScheduleState());
    }
  }, [isUnassignShiftSuccess, unassignShiftErrorCode, unassignShiftMessage]);

  // Callout shift api response state change.
  useEffect(() => {
    if (isCallOutShiftSuccess && callOutShiftErrorCode === HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastSuccess(callOutShiftMessage);
      dispatch(revertAllScheduleState());
      fetchSchedules();
    } else if (!isCallOutShiftSuccess && callOutShiftErrorCode > HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastError(callOutShiftMessage);
      dispatch(revertAllScheduleState());
    }
  }, [isCallOutShiftSuccess, callOutShiftErrorCode, callOutShiftMessage]);

  // Publish shift api response state change.
  useEffect(() => {
    if (isPublishShiftSuccess && publishShiftErrorCode === HTTP_STATUS_200 && publishShiftMessage) {
      toast.toastSuccess(publishShiftMessage);
      dispatch(revertAllAdminScheduleState());
      fetchSchedules();
    } else if (!isPublishShiftSuccess && publishShiftErrorCode > HTTP_STATUS_200 && publishShiftMessage) {
      toast.toastError(publishShiftMessage);
      dispatch(revertAllAdminScheduleState());
    }
  }, [isPublishShiftSuccess, publishShiftErrorCode, publishShiftMessage]);

  // Unpublish shift api response state change.
  useEffect(() => {
    if (isUnPublishShiftSuccess && unPublishShiftErrorCode === HTTP_STATUS_200 && unPublishShiftMessage) {
      toast.toastSuccess(unPublishShiftMessage);
      dispatch(revertAllAdminScheduleState());
      fetchSchedules();
    } else if (!isUnPublishShiftSuccess && unPublishShiftErrorCode > HTTP_STATUS_200 && unPublishShiftMessage) {
      toast.toastError(unPublishShiftMessage);
      dispatch(revertAllAdminScheduleState());
    }
  }, [isUnPublishShiftSuccess, unPublishShiftErrorCode, unPublishShiftMessage]);

  // Callout reasons api response state change.
  useEffect(() => {
    if (isFetchCalloutReasonSuccess && fetchCalloutReasonErrorCode === HTTP_STATUS_200) {
      if (callOutReasons && callOutReasons.length > 0) {
        const reasonOptions = callOutReasons.map((leave: ICalloutReasons): IObject => ({ label: leave.reason, value: leave.reasonId.toString() }));
        setCallOutReasonList(reasonOptions);
        setSelectedReason(reasonOptions[0] ?? null);
      }
    } else if (!isFetchCalloutReasonSuccess && fetchCalloutReasonErrorCode > HTTP_STATUS_200 && fetchCalloutReasonMessage) {
      toast.toastError(fetchCalloutReasonMessage);
      dispatch(revertAllCommonState());
    }
  }, [isFetchCalloutReasonSuccess, fetchCalloutReasonErrorCode, fetchCalloutReasonMessage, callOutReasons]);

  // Start date filter input select event handler.
  const handleStartDateFilterChange = (date: Date | null) => {
    if (date !== null) {
      const endDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_6, moment(date).toDate());
      setScheduleDates(getDatesBetween(moment(date).format(DATE_FORMAT_YYYY_MM_DD), moment(endDt).format(DATE_FORMAT_YYYY_MM_DD)));
      setStartDateSelected(date);
      setEndDateSelected(endDt);
    }
  };

  // Previous, Next button click handler.
  const togglePreviousNextButton = (type: number) => {
    if (type === PrevNextTypes.Prev) {
      const startDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Subtract, NUMBER_7, startDateSelected || moment().toDate());
      const endDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Subtract, NUMBER_7, endDateSelected || moment().toDate());
      setScheduleDates(getDatesBetween(moment(startDt).format(DATE_FORMAT_YYYY_MM_DD), moment(endDt).format(DATE_FORMAT_YYYY_MM_DD)));
      setStartDateSelected(startDt);
      setEndDateSelected(endDt);
    }
    if (type === PrevNextTypes.Next) {
      const startDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_7, startDateSelected || moment().toDate());
      const endDt = getCalculatedDateTime(DateTimeCalculatedTypes.Days, OperationTypes.Add, NUMBER_7, endDateSelected || moment().toDate());
      setScheduleDates(getDatesBetween(moment(startDt).format(DATE_FORMAT_YYYY_MM_DD), moment(endDt).format(DATE_FORMAT_YYYY_MM_DD)));
      setStartDateSelected(startDt);
      setEndDateSelected(endDt);
    }
  };

  // Method to set schedule roster data.
  const setScheduleRosterData = (schItem: ICAScheduleItems) => {
    return schItem.scheduleInfo?.reduce((acc: any, item: IScheduleItem) => {
      let date: string | null = null;

      if (item.shiftFromTime) {
        date = moment.utc(item.shiftFromTime).format(DATE_FORMAT_YYYY_MM_DD);
      }

      if (date) {
        if (!acc[date]) {
          acc[date] = {
            date,
            shifts: [],
            leaves: [],
            holidays: [],
          };
        }

        if (item.shiftFromTime) {
          const itemCopy = { ...item };
          acc[date].shifts.push(item);
          const nextDate = new Date(new Date(date).getTime() + 86400000).toISOString().split('T')[0];
          if (NIGHT_SHIFTS.includes(item.shortName) && scheduleDates.includes(nextDate)) {
            if (!acc[nextDate]) {
              acc[nextDate] = {
                date: nextDate,
                shifts: [],
                leaves: [],
                holidays: [],
              };
            }
            itemCopy.isNextDayNightShift = true;
            acc[nextDate].shifts.push(itemCopy);
          }
        }
      }

      return acc;
    }, {});
  };

  // Method to set leave details to add leaves to the specific date key.
  const setLeaveRosterData = (schItem: ICAScheduleItems, updatedScheduleInfo: any) => {
    let scheduleData = { ...updatedScheduleInfo };
    schItem.leaveInfo?.forEach((leave: ILeaveDetails) => {
      // Get all leave days from the leave from and to date.
      const leaveDays = getDatesBetween(moment(leave.leaveFrom).format(DATE_FORMAT_YYYY_MM_DD), moment(leave.leaveTo).format(DATE_FORMAT_YYYY_MM_DD));
      // Loop through leave days and add leave details object.
      scheduleData = scheduleData ?? {};
      leaveDays.forEach((leaveDate: string) => {
        if (!scheduleData[leaveDate] && scheduleDates.includes(leaveDate)) {
          scheduleData[leaveDate] = {
            date: leaveDate,
            shifts: [],
            leaves: [leave],
            holidays: [],
          };
        } else {
          scheduleData[leaveDate]?.leaves.push(leave);
        }
      });
    });
    return scheduleData;
  };

  // Function to format the schedule data to a bindable cell format.
  const formatScheduleData = () => {
    const holidays = userScheduleAndLeaveDetails && userScheduleAndLeaveDetails?.holidays?.map((day: string) => moment(day).format(DATE_FORMAT_YYYY_MM_DD));
    const scheduleCAData = caUserId > 0 ? userScheduleAndLeaveDetails?.cAList.filter((sch: ICAScheduleItems) => sch.caId === caUserId) : userScheduleAndLeaveDetails?.cAList;
    let newSchedule: any = {};
    const groupedSchedule = (scheduleCAData || []).map((schItem: ICAScheduleItems) => {
      // Set schedule data for the rostaer view.
      newSchedule = setScheduleRosterData(schItem);
      // Set leave data for the roster view.
      newSchedule = setLeaveRosterData(schItem, newSchedule);

      let sortedSchedules: IRosterScheduleView | null = null;
      // Add dates objects not in the schedule list.
      scheduleDates.forEach((date: string) => {
        // If the date is not already a key in schedules, add it with a null value
        if (newSchedule && Object.keys(newSchedule).length > 0) {
          if (!Object.prototype.hasOwnProperty.call(newSchedule, date)) {
            if (holidays && holidays.length > 0 && holidays.includes(date)) {
              newSchedule[date] = {
                date,
                shifts: [],
                leaves: [],
                holidays: ['holiday'],
              };
            } else {
              newSchedule[date] = {
                date,
                shifts: [],
                leaves: [],
                holidays: [],
              };
            }
          }
        } else {
          newSchedule = {};
          newSchedule[date] = {
            date,
            shifts: [],
            leaves: [],
            holidays: [],
          };
        }
      });

      // Sort the schedules by date
      sortedSchedules = Object.keys(newSchedule)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reduce((ar, date) => {
          (ar as any)[date] = newSchedule[date];
          return ar;
        }, {});

      const previousDate = moment(startDateSelected).subtract(NUMBER_1, 'days').format(DATE_FORMAT_YYYY_MM_DD);
      if (sortedSchedules[previousDate]) {
        delete sortedSchedules[previousDate];
      }

      return {
        caId: schItem.caId,
        firstName: schItem.firstName,
        lastName: schItem.lastName,
        empId: schItem.empId,
        colorGroup: schItem.colorGroup,
        lightDuty: schItem.lightDuty,
        volunteer: schItem.volunteer,
        partTime24: schItem.partTime24,
        partTime16: schItem.partTime16,
        partTime20: schItem.partTime20,
        fullTime: schItem.fullTime,
        floatStaff: schItem.floatStaff,
        onPrem: schItem.onPrem,
        student: schItem.student,
        restrictedOt: schItem.restrictedOt,
        actualHours: schItem.actualHours,
        assignedHours: schItem.assignedHours,
        schedules: sortedSchedules,
      };
    });
    setScheduleList(groupedSchedule);
  };

  // Fetch schedules and leaves api response state side effect handling.
  useEffect(() => {
    if (isUserScheduleAndLeaveDetailsSuccess && userScheduleAndLeaveDetailsErrorCode === HTTP_STATUS_200 && userScheduleAndLeaveDetailsMessage && userScheduleAndLeaveDetails) {
      formatScheduleData();
    } else if (!isUserScheduleAndLeaveDetailsSuccess && userScheduleAndLeaveDetailsErrorCode > HTTP_STATUS_200 && userScheduleAndLeaveDetailsMessage) {
      toast.toastError(userScheduleAndLeaveDetailsMessage);
    }
  }, [isUserScheduleAndLeaveDetailsSuccess, userScheduleAndLeaveDetailsErrorCode, userScheduleAndLeaveDetailsMessage, userScheduleAndLeaveDetails]);

  useEffect(() => {
    formatScheduleData();
  }, []);

  // Method to show leave details.
  const manageLeaveDetails = (caId: number, id: number) => {
    const caInfo = userScheduleAndLeaveDetails && userScheduleAndLeaveDetails.cAList.find((ca: ICAScheduleItems) => ca.caId === caId);
    const leaveInfo = caInfo?.leaveInfo.find((item: ILeaveDetails) => item.leaveRequestId === id);
    setCALeaveDetails(leaveInfo || null);
    setSelectedCAName(caInfo ? `${caInfo.firstName || ''} ${caInfo.lastName || ''}` : '');
    showLeaveDetailsPopup(true);
  };

  // Method to un-assign shift.
  const unAssignShift = (shift: number, caId: number, shiftStart: string) => {
    setUnAssignRequest((prev) => ({
      ...prev,
      caId,
      shift,
      shiftStart,
    }));
    showUnAssignPopup(true);
  };

  // Method to call-out shift.
  const callOutCAFromShift = (shift: number, shiftStart: string, caId: number) => {
    dispatch(getCalloutReasons());
    setCallOutShiftRequest((prev) => ({
      ...prev,
      caId,
      shiftId: shift,
      shiftStart,
      currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
      reasonId: NUMBER_0,
      description: '',
    }));
    setErrorFields(null);
    setCallOutReasonText('');
    showCallOutPopup(true);
  };

  // Method to publish shift.
  const publishCAShift = (shift: number) => {
    setPublishShiftId(shift);
    showPublishPopup(true);
  };

  // // Method to unpublish shift.
  const unPublishCAShift = (shift: number) => {
    setUnPublishShiftId(shift);
    showUnPublishPopup(true);
  };

  // My schedule button click handler.
  const onScheduleButtonClick = (id: number, type: number, shiftDate: string, caId: number) => {
    switch (type) {
      case MyScheduleActions.LeaveDetails:
        manageLeaveDetails(caId, id);
        break;
      case MyScheduleActions.Unassign:
        unAssignShift(id, caId, shiftDate);
        break;
      case MyScheduleActions.Callout:
        callOutCAFromShift(id, shiftDate, caId);
        break;
      case MyScheduleActions.Publish:
        publishCAShift(id);
        break;
      case MyScheduleActions.Unpublish:
        unPublishCAShift(id);
        break;
      default:
        break;
    }
  };

  // Unassign confirmation button click handler.
  const onUnAssignClick = () => {
    if (unAssignRequest) {
      dispatch(unassignCA(unAssignRequest));
    }
    showUnAssignPopup(false);
  };

  // Callout confirmation button click handler.
  const onShiftCallOutClick = async () => {
    const callOutReasonForm = {
      callOutReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: callOutReasonText,
    };
    const errorResult = await validateForm(callOutReasonForm, CALLOUT_REASON_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (callOutShiftRequest && Object.keys(errorResult).length === NUMBER_0) {
      const callOutRequest = { ...callOutShiftRequest };
      callOutRequest.currentTime = moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET);
      callOutRequest.reasonId = selectedReason?.value ? Number(selectedReason.value) : NUMBER_0;
      callOutRequest.description = callOutReasonText;
      dispatch(callOutShift(callOutRequest));
      showCallOutPopup(false);
    }
  };

  // Publish shift confirmation button click handler.
  const onShiftPublishClick = () => {
    if (publishShiftId > 0) {
      dispatch(publishShift({ shiftIds: [publishShiftId] }));
    }
    showPublishPopup(false);
  };

  // Unpublish shift confirmation button click handler.
  const onShiftUnPublishClick = () => {
    if (unPublishShiftId > 0) {
      dispatch(unpublishShift({ shiftIds: [unPublishShiftId] }));
    }
    showUnPublishPopup(false);
  };

  // Filter apply button event handler.
  const handleApply = (filters: { publishStatus: number[]; selectedIndicators: IKeyIndicators }) => {
    setSelectedPublishStatus(filters.publishStatus);
    setIndicators(filters.selectedIndicators);
  };

  // Callout reason type dropdown select change handler.
  const onCalloutReasonSelect = (val: IObject) => {
    setSelectedReason(val);
  };

  // Callout reason input change handler.
  const onCalloutReasonChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const callOutReasonForm = {
      callOutReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: value,
    };
    const errorResult = await validateForm(callOutReasonForm, CALLOUT_REASON_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setCallOutReasonText(value);
  };

  // CA search input field button click event handler.
  const onCASearch = (val: string) => {
    setSearchText(val);
  };

  // Bind breadcrumbs based on redirecting page.
  const setBreadCrumbs = () => {
    const prevUrl = (location?.state?.prevUrl || '').split('/')?.[1] || null;
    const shiftDetailsUrl = AdminRoutePath.SCHShiftDetails.split('/')?.[1] || null;
    return (
      <Breadcrumb>
        <Breadcrumb.Item href={`/${prevUrl}` === AdminRoutePath.SCHManageCA ? location?.state?.prevUrl : AdminRoutePath.ManageSchedule}>
          {`/${prevUrl}` === AdminRoutePath.SCHManageCA ? Strings.Menu.ManageCA : Strings.HD.ManageSchedules}
        </Breadcrumb.Item>
        {`/${prevUrl}` === `/${shiftDetailsUrl}` && <Breadcrumb.Item href={location?.state?.prevUrl}>{Strings.Shift.ShiftDetails.Header}</Breadcrumb.Item>}
        <Breadcrumb.Item active>{Strings.HD.ViewSchedules}</Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  return (
    <div>
      <div>{setBreadCrumbs()}</div>
      <div className="pageheader">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{Strings.HD.ViewSchedules}</h1>
          </Col>
          <Col xs="auto">
            <Button variant="outline-primary" onClick={() => navigate(location?.state?.prevUrl || AdminRoutePath.ManageSchedule)}>
              {Strings.Button.Back}
            </Button>
          </Col>
        </Row>
      </div>
      <div className="filter-main">
        <Row className="d-flex justify-content-between">
          <Col xl={6} md={7} sm={12}>
            <Row className="align-items-center">
              <Col xs className="calendar-mob-left">
                <KKIDatepicker
                  id="kkiStartDate"
                  name="kkiStartDate"
                  placeholder="placeholder"
                  value={startDateSelected}
                  dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER}
                  onChangeDatepicker={(date: any) => handleStartDateFilterChange(date)}
                />
              </Col>
              <Col xs="auto" className="px-0">
                {Strings.Schedule.DateFilterText}
              </Col>
              <Col xs className="calendar-mob-right">
                <KKIDatepicker id="kkiEndDate" name="kkiEndDate" placeholder="placeholder" value={endDateSelected} dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER} disabled />
              </Col>
              <Col xs="auto" className="ps-0">
                <ButtonGroup aria-label="week change" className="weekchange">
                  <Button variant="secondary" onClick={() => togglePreviousNextButton(PrevNextTypes.Prev)}>
                    <Arrow />
                  </Button>
                  <Button variant="secondary" onClick={() => togglePreviousNextButton(PrevNextTypes.Next)}>
                    <Arrow />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
          <Col className="m-sm-16">
            <Col xl={6} className="min-w-285">
              <KKISearch placeholder={Strings.Input.SearchbyNameEmployeeID} value={searchText} onSearch={(param: string) => onCASearch(param)} maxLength={NUMBER_50} />
            </Col>
          </Col>
          <Col xs="auto" className="btn-container d-flex m-sm-16">
            <ScheduleFilter onChange={handleApply} selectedPublishValues={selectedPublishStatus} selectedIndicators={indicators} />
          </Col>
        </Row>
      </div>
      <Row className="mt-3">
        <Col>
          <h3>{Strings.Schedule.SubHeader}</h3>
        </Col>
        <Col xs="auto" className="schedule-legend">
          <span className="draft">{Strings.Filter.Draft}</span>
          <span className="published">{Strings.Filter.Published}</span>
          <span className="oncall">{Strings.Label.OnCall}</span>
          <span className="legent4 d-inline-block">{Strings.Filter.Legent4}</span>
        </Col>
      </Row>
      <div className="view-schedule-table table-wrapper" ref={tableRef}>
        <Table bordered className="schedule-table">
          <thead>
            <tr className="sticky">
              {
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <th className="sticky">{Strings.Label.CA}</th>
              }
              {scheduleDates.map((dt: string) => (
                <th>
                  {moment(dt, DATE_FORMAT_YYYY_MM_DD).format('ddd').toUpperCase()}
                  <span>{moment(dt, DATE_FORMAT_YYYY_MM_DD).format('DD')}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {scheduleList &&
              scheduleList.length > 0 &&
              scheduleList.map((sch: any) => (
                <tr>
                  <td>
                    <div className="d-flex align-items-center fw-sb">
                      {`${sch.firstName} ${sch.lastName}`} <span className={`colorcode ms-1 ${ColorGroups[sch.colorGroup].toLowerCase()}`} />
                    </div>
                    <span className="d-block fw-sb employee-id">
                      {Strings.Label.EmpId}: {sch.empId}
                    </span>
                    {getKeyIndicators({
                      floatStaff: sch?.floatStaff,
                      fullTime: sch?.fullTime,
                      lighDuty: sch?.lightDuty,
                      volunteer: sch?.volunteer,
                      student: sch?.student,
                      restrictedOt: sch?.restrictedOt,
                      partTime24: sch?.partTime24,
                      partTime16: sch?.partTime16,
                      partTime20: sch?.partTime20,
                    })}
                    <span className="d-block fw-sb employee-id">
                      {Strings.Label.WorkHrs}: {`${convertFloatToTime(sch.actualHours)}/${convertFloatToTime(sch.assignedHours)}`}
                    </span>
                  </td>
                  {sch.schedules &&
                    Object.keys(sch.schedules).length > 0 &&
                    Object.keys(sch.schedules).map((dt: string, index: number) => (
                      <td>
                        <ScheduleCard
                          key={sch.schedules[dt]?.date || index}
                          date={sch.schedules[dt]?.date || ''}
                          shifts={sch.schedules[dt]?.shifts || []}
                          leaves={sch.schedules[dt]?.leaves || []}
                          holidays={sch.schedules[dt]?.holidays || []}
                          caId={sch.caId}
                          filterItems={null}
                          isLd={false}
                          onButtonClick={(id: number, shiftDate: string, type: number) => onScheduleButtonClick(id, type, shiftDate, sch.caId)}
                        />
                      </td>
                    ))}
                </tr>
              ))}
            {!scheduleList ||
              (scheduleList && scheduleList.length <= 0 && (
                <tr>
                  <td colSpan={8} className="mt-4 text-center">
                    {Strings.Text.NoDataFound}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {(isUnassignShiftLoading || isCallOutShiftLoading || isPublishShiftLoading || isUnPublishShiftLoading || isUserScheduleAndLeaveDetailsLoading) && <Loader />}
      <Modal show={unAssignPopup} onHide={() => showUnAssignPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.UnAssignPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showUnAssignPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onUnAssignClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={callOutPopup} onHide={() => showCallOutPopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Shift.Button.CallOut}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <Col className="mb-3">
            <KKISelect
              id="reasonTypes"
              label={Strings.Label.SelectReason}
              name="reasonTypes"
              className="custom-select"
              placeholder={Strings.Label.SelectReason}
              searchvalue={false}
              options={callOutReasonList}
              value={selectedReason}
              onSelectChange={onCalloutReasonSelect}
            />
          </Col>
          {selectedReason?.label === OTHER_REASON_LABEL && (
            <Col>
              <KKIInput
                id="declineReason"
                label={Strings.Label.EnterReason}
                name="declineReason"
                type="text"
                as="textarea"
                rows={3}
                placeholder={Strings.Leave.PlaceHolder.EnterReason}
                value={callOutReasonText}
                onChange={onCalloutReasonChange}
                alert={errorFields?.reason || ''}
              />
            </Col>
          )}
          <Col className="mt-4 text-end">
            <Button variant="outline-primary" type="button" onClick={() => showCallOutPopup(false)}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" type="button" className="ms-3" onClick={() => onShiftCallOutClick()}>
              {Strings.Button.Submit}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={publishPopup} onHide={() => showPublishPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.PublishPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showPublishPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onShiftPublishClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={unPublishPopup} onHide={() => showUnPublishPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.UnPublishPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showUnPublishPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onShiftUnPublishClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={leaveDetailsPopup} onHide={() => showLeaveDetailsPopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{`${Strings.Leave.LeaveDetails} Of ${selectedCAName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LeaveDetails data={caLeaveDetails} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ViewSchedules;
