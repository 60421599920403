/**
 * @file   src\containers\Scheduler\ManageCA\ManageCA.tsx
 * @brief  Scheduler-manage ca page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Row, Col, Table, Button, Form, useState, useNavigate, useLocation, useEffect, Modal, moment } from '../../../components/ThirdPartyComponents';
import KKISearch from '../../../components/KKISearch';
import Strings from '../../../assests/strings/Strings.json';
import { CAUserProfile } from '../../../interfaces/caInterface';
import { ColorGroups, AdminRoutePath, ColorGroupNames } from '../../../utils/enums';
import { NUMBER_1, CA_NAME, DESC, ASC, HTTP_STATUS_200, MOT_POSITION, NUMBER_50, CURRENT_DATE_FORMAT_WITH_OFFSET } from '../../../utils/constants';
import SortUp from '../../../assests/icons/SortUp';
import SortDown from '../../../assests/icons/SortDown';
import Loader from '../../../components/Loader';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getAllCAUsers, activateOrDeactivateCA, revertAllManageCAState } from '../../../store/actions/manageCAAction';
import { RootState } from '../../../store';
import { MessageToaster } from '../../../utils/toastUtils';
import Pagination from '../../../components/Paginate';
import Sort from '../../../assests/icons/Sort';
import { formatPhoneNumber, stringFormat } from '../../../utils/helper';

// Declare page item count to be displayed per page.

// Toast object creation.
const toast = new MessageToaster();

const SCHManageCA = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Navigate object creation.
  const navigate = useNavigate();

  // Create location object to access location info.
  const location = useLocation();

  // Access redux state variables.
  const { caData, total, isFetchCALoading, limit } = useAppSelector((state: RootState) => state.manageCA);
  const { isUpdateCAStatusSuccess, updateCAStatusErrorCode, updateCAStatusMessage } = useAppSelector((state: RootState) => state.manageCA);

  // Initialize component state variables.
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortField, setSortField] = useState<string>(CA_NAME);
  const [sortOrder, setSortOrder] = useState<string>(DESC);
  const [statusChangePopup, showStatusPopup] = useState<boolean>(false);
  const [selectedCA, setSelectedCA] = useState<CAUserProfile | null>(null);
  const [modalActionText, setModalActionText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(NUMBER_1);

  // Component initial loading.
  useEffect(() => {
    if (location.state) {
      window.history.replaceState({}, '');
    }
    return () => {
      dispatch(revertAllManageCAState());
    };
  }, []);

  // Fetch all CA users whenever dependencies change
  useEffect(() => {
    dispatch(getAllCAUsers({ keyword: searchQuery, page: currentPage, sort: sortField, order: sortOrder }));
  }, [dispatch, searchQuery, currentPage, sortField, sortOrder]);

  // Restore state when coming back from the detail page
  useEffect(() => {
    if (location.state) {
      const { fromPage, searchQuery: statesearchQuery, sortField: statesortField, sortOrder: stateSortOrder } = location.state;
      setCurrentPage(fromPage || 1);
      setSearchQuery(statesearchQuery || '');
      setSortField(statesortField || CA_NAME);
      setSortOrder(stateSortOrder || DESC);
    }
  }, [location.state]);

  // Update status api response state side effect handling.
  useEffect(() => {
    if (isUpdateCAStatusSuccess && updateCAStatusErrorCode === HTTP_STATUS_200 && updateCAStatusMessage) {
      dispatch(getAllCAUsers({ keyword: searchQuery, page: currentPage, sort: sortField, order: sortOrder }));
      toast.toastSuccess(updateCAStatusMessage);
    } else if (!isUpdateCAStatusSuccess && updateCAStatusErrorCode > HTTP_STATUS_200 && updateCAStatusMessage) {
      toast.toastError(updateCAStatusMessage);
    }
  }, [isUpdateCAStatusSuccess, updateCAStatusMessage, updateCAStatusErrorCode]);

  // Handle pagination
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  // Handle search action
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(NUMBER_1);
  };

  // Handle sort action
  const handleSort = (field: string) => {
    if (field === CA_NAME) {
      setSortField('caName');
      setSortOrder(sortOrder === ASC ? DESC : ASC);
    } else if (sortField === field) {
      setSortOrder(sortOrder === ASC ? DESC : ASC);
    } else {
      setSortField(field);
      setSortOrder(ASC);
    }
  };

  // Calculate range of currently displayed items
  const startItem = (currentPage - 1) * limit + 1;
  const endItem = Math.min(currentPage * limit, total);
  // Toggles the status of the CA (Active/Inactive) when the switch is checked/unchecked.
  const handleStatusToggle = (ca: CAUserProfile) => {
    setSelectedCA(ca);
    setModalActionText(ca.status ? Strings.Text.Scheduler.StatusDeactivateText : Strings.Text.Scheduler.StatusActivateText);
    showStatusPopup(true);
  };
  // Handle modal confirmation
  const handleConfirmStatusChange = () => {
    if (selectedCA) {
      const newStatus = !selectedCA.status;
      dispatch(activateOrDeactivateCA({ caId: selectedCA.caId, status: newStatus, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));
      showStatusPopup(false);
    }
  };
  return (
    <div>
      <div className="pageheader">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{Strings.HD.ManageCA}</h1>
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12} className="m-xs-16">
            <KKISearch placeholder={Strings.Input.SearchbyNameEmployeeID} onSearch={handleSearch} maxLength={NUMBER_50} value={searchQuery} />
          </Col>
        </Row>
      </div>
      <div className="table-border">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th onClick={() => handleSort(CA_NAME)} style={{ cursor: 'pointer' }}>
                {Strings.Label.Name}
                <span className="ms-1">
                  {sortField !== CA_NAME && <Sort />}
                  {sortField === CA_NAME && sortOrder === DESC && <SortDown />}
                  {sortField === CA_NAME && sortOrder === ASC && <SortUp />}
                </span>
              </th>
              <th>{Strings.Label.EmpId}</th>
              <th>{Strings.Label.Contact}</th>
              <th>{Strings.Label.Email}</th>
              <th>{Strings.Label.ColorGroup}</th>
              <th onClick={() => handleSort(MOT_POSITION)} style={{ cursor: 'pointer' }}>
                {Strings.Label.MOTPosition}
                <span className="ms-1">
                  {sortField !== MOT_POSITION && <Sort />}
                  {sortField === MOT_POSITION && sortOrder === DESC && <SortDown />}
                  {sortField === MOT_POSITION && sortOrder === ASC && <SortUp />}
                </span>
              </th>
              <th>{Strings.Label.Status}</th>
              <th className="w-160">{Strings.Label.Action}</th>
            </tr>
          </thead>
          <tbody>
            {caData && caData.length > 0 ? (
              caData.map((ca: CAUserProfile, index: number) => (
                <tr key={ca.caId}>
                  <td>
                    <a
                      href={void 0}
                      onClick={() =>
                        navigate(`${AdminRoutePath.SCHCADetails.replace(':caId', ca.caId.toString())}?type=1`, {
                          state: {
                            fromPage: currentPage,
                            searchQuery,
                            sortField,
                            sortOrder,
                          },
                        })
                      }
                    >
                      {ca.caFirstName} {ca.caLastName}
                    </a>
                  </td>
                  <td>{ca.empId}</td>
                  <td>{formatPhoneNumber(ca?.phone || '')}</td>
                  <td>{ca.email}</td>
                  <td>{ColorGroupNames[ca.colorGroup as ColorGroups]}</td>
                  <td>{ca.motPosition}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={`status-switch-${index}`}
                      label={ca.status ? Strings.Label.Active : Strings.Label.Inactive}
                      checked={ca.status}
                      onChange={() => handleStatusToggle(ca)} // Pass the current CA to the handler
                    />
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => navigate({ pathname: `${AdminRoutePath.SCHViewSchedules}/${ca.caId}` }, { state: { prevUrl: location.pathname } })}
                    >
                      {Strings.Button.ViewSchedules}
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  {Strings.Text.NoDataFound}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {isFetchCALoading && <Loader />}
      <Modal show={statusChangePopup} onHide={() => showStatusPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>
            {selectedCA && (
              <>
                {modalActionText} {selectedCA.caFirstName} {selectedCA.caLastName}?
              </>
            )}
          </p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showStatusPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={handleConfirmStatusChange} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-between mt-4">
        <span>{total > 0 ? stringFormat(Strings.Text.ShowingUsers, startItem, endItem, total) : ''}</span>
        {total > limit && (
          //  <Pagination totalCount={total} pageLimit={pageSize} setCurrentPage={handlePageChange} currentPage={currentPage - 1} prevPage={-1} />
          <Pagination totalCount={total} pageLimit={limit} setCurrentPage={handlePageChange} currentPage={currentPage - 1} prevPage={-1} />
        )}
      </div>
    </div>
  );
};
export default SCHManageCA;
