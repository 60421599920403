/**
 * @file   src\utils\helper.ts
 * @brief  This file is responsible for generating helper methods.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import CryptoJS from 'crypto-js';
import alerts from '../messages/alertMessages.json';
import { matchPath, moment } from '../components/ThirdPartyComponents';
import { ColorGroups, DateTimeCalculatedTypes, KeyIndicatorsForCALLoutCAList, OperationTypes } from './enums';
import {
  ARROWLEFT_KEY,
  ARROWRIGHT_KEY,
  BACKSPACE_KEY,
  DATE_FORMAT_HH_MM_SS,
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_YYYY_MM_DD_HH_MM_SS,
  DELETE_KEY,
  NUMBER_0,
  NUMBER_10,
  NUMBER_ONLY_REGEX,
  QUERY_STRING_ARRAY_FORMAT,
  TAB_KEY,
} from './constants';

// Encrypt the plain text
export const encryptText = (plainText: string) => {
  return CryptoJS.AES.encrypt(plainText, process.env.REACT_APP_ENC_DEC_KEY || '').toString();
};

// Decrypt the encrypted text
export const decryptText = (encryptedText: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_ENC_DEC_KEY || '');
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};

// Method to get values from alert json based on dynamic keys.
export const getAlertMessage = (key: string) => {
  const alertMessages: { [key: string]: string } = alerts;
  return alertMessages[key] !== undefined ? alertMessages[key] : '';
};

// Function that build query string.
export function buildQueryString(params: any, arrayFormat: string | null = null): string {
  const queryString = Object.entries(params)
    .map(([key, value]: [string, any]) => {
      if (Array.isArray(value)) {
        if (arrayFormat === QUERY_STRING_ARRAY_FORMAT && value.length > NUMBER_0) {
          // Repeat the key for each value
          return value.map((v) => `${key}=${encodeURIComponent(v.toString())}`).join('&');
        }
      }
      // Handle non-array values
      return `${key}=${encodeURIComponent(value?.toString())}`;
    })
    .join('&');

  return queryString;
}

// Match exact root path method.
export const checkPathMatched = (data: string[] | string, pathname: string) => {
  return Array.isArray(data) ? !!data.find((path) => matchPath(path, pathname)) : !!matchPath(data, pathname);
};

// Function that check string empty/null/undefined.
export const isEmpty = (value: string | null) => {
  return value == null || (typeof value === 'string' && value.trim().length === 0);
};

// Format string with args.
export const stringFormat = (template: string, ...args: any[]) => {
  return template.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
};

// Function to get calculated date time based on the args.
export const getCalculatedDateTime = (manipulateProperty: number, operator: number, duration: number, date: Date | null = moment().toDate()) => {
  let modifiedDateTime = date;
  switch (manipulateProperty) {
    case DateTimeCalculatedTypes.Days:
      modifiedDateTime = operator === OperationTypes.Add ? moment(date).add(duration, 'd').toDate() : moment(date).subtract(duration, 'd').toDate();
      break;
    case DateTimeCalculatedTypes.Week:
      modifiedDateTime = operator === OperationTypes.Add ? moment(date).add(duration, 'w').toDate() : moment(date).subtract(duration, 'w').toDate();
      break;
    case DateTimeCalculatedTypes.Month:
      modifiedDateTime = operator === OperationTypes.Add ? moment(date).add(duration, 'M').toDate() : moment(date).subtract(duration, 'M').toDate();
      break;
    case DateTimeCalculatedTypes.Year:
      modifiedDateTime = operator === OperationTypes.Add ? moment(date).add(duration, 'y').toDate() : moment(date).subtract(duration, 'y').toDate();
      break;
    default:
      modifiedDateTime = date;
      break;
  }
  return modifiedDateTime;
};

// Function to get days between two dates.
export const getDateDifference = (startDate: string | null, endDate: string | null) => {
  if (startDate !== null && endDate !== null) {
    return moment(endDate).diff(moment(startDate), 'days');
  }
  return NUMBER_0;
};

// Get data from local storage based on the given key.
export const getDataFromStorage = (key: string, decrypt?: boolean) => {
  const data = localStorage.getItem(key)!;
  const parsedData = JSON.parse(decrypt && data ? decryptText(data) : data);
  return parsedData;
};

// Set local storage data.
export const setStorageData = (key: string, data: string, encrypt?: boolean) => {
  localStorage.setItem(key, encrypt && data ? encryptText(data) : data);
};

// Function to allow only integer, backspace, delete, arrow left/right and tab to an input field.
export const allowIntegerOnly = (event: any) => {
  const { key, ctrlKey } = event;
  // Allow backspace, delete, arrow keys, and tab
  if (key === BACKSPACE_KEY || key === DELETE_KEY || key === ARROWLEFT_KEY || key === ARROWRIGHT_KEY || key === TAB_KEY || (ctrlKey && (key === 'v' || key === 'V'))) {
    return;
  }
  // Allow numbers 0-9
  if (!NUMBER_ONLY_REGEX.test(key)) {
    event.preventDefault();
  }
};

// Get all dates between the given start and end date.
export const getDatesBetween = (startDate: string, endDate: string) => {
  const dates = [];
  const currentDate = moment(startDate).toDate();
  while (currentDate <= moment(endDate).toDate()) {
    dates.push(moment(currentDate).format(DATE_FORMAT_YYYY_MM_DD));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

// Function to get key indicators
export const getKeyIndicators = (ca: {
  volunteer?: boolean;
  partTime24?: boolean;
  partTime16?: boolean;
  partTime20?: boolean;
  fullTime?: boolean;
  lighDuty?: boolean;
  restrictedOt?: boolean;
  floatStaff?: boolean;
  onPrem?: boolean;
  student?: boolean;
}): string => {
  return (
    [
      ca.volunteer && KeyIndicatorsForCALLoutCAList.Volunteer,
      ca.floatStaff && KeyIndicatorsForCALLoutCAList.FloatStaff,
      ca.student && KeyIndicatorsForCALLoutCAList.Student,
      ca.partTime16 && KeyIndicatorsForCALLoutCAList.PartTime16,
      ca.partTime20 && KeyIndicatorsForCALLoutCAList.PartTime20,
      ca.partTime24 && KeyIndicatorsForCALLoutCAList.PartTime24,
      ca.fullTime && KeyIndicatorsForCALLoutCAList.fullTime,
      ca.lighDuty && KeyIndicatorsForCALLoutCAList.LightDuty,
      ca.restrictedOt && KeyIndicatorsForCALLoutCAList.RestrictedOT,
      ca.onPrem && KeyIndicatorsForCALLoutCAList.OnPrem,
    ]
      .filter(Boolean)
      .join(', ') || '-'
  );
};

// Function to get color group name from enum
export const getColorGroupName = (colorGroup: number): string => {
  switch (colorGroup) {
    case ColorGroups.Red:
      return 'Red';
    case ColorGroups.Yellow:
      return 'Yellow';
    case ColorGroups.Blue:
      return 'Blue';
    default:
      return 'None';
  }
};

// Format phone number string to US format
export const formatPhoneNumber = (phone: string) => {
  const phoneWithoutCountryCode = phone.substring(phone.length - NUMBER_10);
  let formatedPhoneNumber = phoneWithoutCountryCode;
  if (phoneWithoutCountryCode && phoneWithoutCountryCode !== '' && phoneWithoutCountryCode.length >= 10) {
    formatedPhoneNumber = phoneWithoutCountryCode.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  return formatedPhoneNumber;
};

// Method to get N digit random number.
export const getRandomNDigitNumber = (n: number) => {
  const min = 10 ** (n - 1);
  const max = 10 ** n - 1;

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Method to format decimal to time(hh:mm) format.
export const formatToTime = (time: number) => {
  const hours = Math.floor(time); // Get the whole number for hours
  const minutes = Math.round((time - hours) * 100); // Extract minutes from decimal

  // Pad hours and minutes with leading zeros if necessary
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Combine hours and minutes
  return `${formattedHours}:${formattedMinutes}`;
};

// Method to convert the time to float format.
export const convertTimeToFloat = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours + minutes / 60;
};

// Method to convert the float to time format
export const convertFloatToTime = (timeFloat: number) => {
  const hours = Math.floor(timeFloat);
  const minutes = Math.round((timeFloat - hours) * 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

// Method to converts a time in HH:mm format to total seconds
export const convertTimeToSeconds = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  if (Number.isNaN(hours) || Number.isNaN(minutes) || hours < 0 || minutes < 0 || minutes >= 60) {
    return NaN;
  }
  return hours * 3600 + minutes * 60;
};

// Method to format given date time and compare the date time is greater than current date time.
export const isCurrentTimeIsBeforeShiftStartTime = (dateTime: string) => {
  if (dateTime === '') {
    return false;
  }
  const formattedDate = moment.utc(dateTime).format('YYYY-MM-DD');
  const formattedTime = moment.utc(dateTime).format('HH:mm:ss');
  const newDateTime = moment(`${formattedDate} ${formattedTime}`, 'YYYY-MM-DD HH:mm:ss');
  return moment().isBefore(moment.utc(newDateTime));
};

// Method to get enum key name based on value and enum name.
export const getEnumKeyByValue = <T>(enumObj: T, value: number): keyof T | undefined => {
  const keys = Object.keys(enumObj as any) as (keyof T)[];
  const key = keys.find((k) => enumObj[k] === value);
  return key;
};

// Method to get hour difference from two dates.
export const calculateHourDifference = (checkInTimeValue: Date | null, checkOutTimeValue: Date | null) => {
  if (checkInTimeValue && checkOutTimeValue) {
    const checkInMoment = moment(checkInTimeValue);
    const checkOutMoment = moment(checkOutTimeValue);
    const duration = moment.duration(checkOutMoment.diff(checkInMoment));
    return duration.asHours().toString();
  }
  return '';
};

// Mthod to convert ISO string to same datetime.
export const convertToDbTime = (dateTime: string) => {
  if (dateTime) {
    const time = moment.utc(dateTime).format(DATE_FORMAT_HH_MM_SS);
    const dt = moment.utc(dateTime).format(DATE_FORMAT_YYYY_MM_DD);
    return moment(`${dt} ${time}`, DATE_FORMAT_YYYY_MM_DD_HH_MM_SS).toDate();
  }
  return null;
};
