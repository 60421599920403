/**
 *@file src\components\KKISelect.tsx
 *@brief Reusable select component
 *@date July, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */

import '../styles/KKISelect.scss';
import Select from 'react-select';

import { IKKISelect } from '../interfaces/generalInterface';

const KKISelect = ({ id, name, options, className, placeholder, disabled, label, alert, value, searchvalue, onSelectChange }: IKKISelect) => {
  return (
    <div className="kki-select">
      {label && <label htmlFor="kki-select">{label}</label>}
      <Select
        className={alert && alert !== '' ? 'custom-select error' : 'custom-select'}
        id={id}
        name={name}
        options={options}
        classNamePrefix={className}
        placeholder={placeholder}
        isDisabled={disabled}
        isSearchable={searchvalue}
        onChange={(val: any) => onSelectChange?.(val)}
        value={value || ''}
      />

      {alert && alert !== '' && <span className="error">{alert}</span>}
    </div>
  );
};

export default KKISelect;
