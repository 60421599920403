/**
 * @file   src\components\ShiftCard.tsx
 * @brief  Component for listing schedule.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Card, Col, Row, Button, moment, useNavigate } from './ThirdPartyComponents';
import { IShift, IShiftDetailsCard } from '../interfaces/scheduleInterface';
import Published from '../assests/icons/Publish';
import Draft from '../assests/icons/Draft';
import DayShift from '../assests/icons/DayShift';
import NightShift from '../assests/icons/NightShift';
import MidShift from '../assests/icons/MidShift';
import { MyScheduleActions, RoleTypes, AdminRoutePath, ScheduleTabs, NoShow } from '../utils/enums';
import { convertFloatToTime, getDataFromStorage, getEnumKeyByValue, isCurrentTimeIsBeforeShiftStartTime } from '../utils/helper';
import { DATE_FORMAT_MM_DD_YYYY, DAY_SHIFTS, MID_SHIFTS, NIGHT_SHIFTS, NUMBER_2, STORAGE_USER, NUMBER_0, DATE_FORMAT_MM_DD_YYYY_HMMA_DDD } from '../utils/constants';

const ShiftDetailsCard = ({ shiftDetails, onButtonClick, type, handleSaveClick, onPublishButtonClick, caId, caListCount, isLd, onUnPublishButtonClick }: IShiftDetailsCard) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Access role from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  const role = userInfo?.roleId || 0;

  // Determine what to show for color group based on required values
  const renderOpenSlots = () => {
    const { yellowRequired, blueRequired, redRequired, yellowCount, blueCount, redCount, whiteRequired, whiteCount } = shiftDetails || {};

    // Check if all required values are zero
    if (yellowRequired === NUMBER_0 && blueRequired === NUMBER_0 && redRequired === NUMBER_0 && whiteRequired === NUMBER_0) {
      return <span>None</span>;
    }

    return (
      <>
        {(yellowRequired !== NUMBER_0 || yellowCount !== NUMBER_0) && (
          <span className="openslot yellow">
            {yellowCount || NUMBER_0}/{yellowRequired || NUMBER_0}
          </span>
        )}
        {(blueRequired !== NUMBER_0 || blueCount !== NUMBER_0) && (
          <span className="openslot blue">
            {blueCount || NUMBER_0}/{blueRequired || NUMBER_0}
          </span>
        )}
        {(redRequired !== NUMBER_0 || redCount !== NUMBER_0) && (
          <span className="openslot red">
            {redCount || NUMBER_0}/{redRequired || NUMBER_0}
          </span>
        )}
        {(whiteRequired !== NUMBER_0 || whiteCount !== NUMBER_0) && (
          <span className="openslot white">
            {whiteCount || NUMBER_0}/{whiteRequired || NUMBER_0}
          </span>
        )}
      </>
    );
  };

  // Render actions for scheduled shifts.
  const renderScheduledActions = () => {
    if (shiftDetails?.scheduleId && shiftDetails?.published) {
      return (
        <Button variant="primary" type="submit" onClick={() => onButtonClick?.(shiftDetails?.shiftId || 0, shiftDetails?.shiftFromTime || '', MyScheduleActions.Callout)}>
          {Strings.Shift.Button.CallOut}
        </Button>
      );
    }
    if (isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftFromTime || '')) {
      if (shiftDetails?.scheduleId && !shiftDetails?.published) {
        return (
          <Button variant="primary" type="submit" onClick={() => onButtonClick?.(shiftDetails?.shiftId || 0, shiftDetails?.shiftFromTime || '', MyScheduleActions.Unassign)}>
            {Strings.Shift.Button.UnAssign}
          </Button>
        );
      }
    }
    return <></>;
  };

  // Button rendering for CA role
  const renderCAButtons = () => {
    if (isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftFromTime || '')) {
      if (!shiftDetails?.scheduleId && shiftDetails?.published) {
        return (
          <Button variant="primary" type="submit" onClick={() => navigate(`/swap-request/${shiftDetails.shiftId}`, { state: { from: NUMBER_2 } })}>
            {Strings.Shift.Button.Swap}
          </Button>
        );
      }

      if (!shiftDetails?.scheduleId && !shiftDetails?.published) {
        return (
          <>
            <Button variant="primary" type="submit" onClick={() => onButtonClick?.(shiftDetails?.shiftId || 0, shiftDetails?.shiftFromTime || '', MyScheduleActions.Assign)}>
              {Strings.Button.RequestShift}
            </Button>
            {(shiftDetails?.onCallRequired ?? NUMBER_0) > NUMBER_0 && (
              <Button
                variant="primary"
                type="submit"
                onClick={() => onButtonClick?.(shiftDetails?.shiftId || 0, shiftDetails?.shiftFromTime || '', MyScheduleActions.AssignAsOnCall)}
              >
                {Strings.Button.RequestOnCall}
              </Button>
            )}
          </>
        );
      }
    }
    return renderScheduledActions();
  };

  // Button rendering for Scheduler role
  const renderSchedulerButtons = () => (
    <>
      {!shiftDetails?.published && (
        <Button variant="outline-primary" type="submit" onClick={() => onPublishButtonClick?.()}>
          {Strings.Button.Publish}
        </Button>
      )}
      {shiftDetails?.published && (
        <Button variant="outline-primary" type="submit" onClick={() => onUnPublishButtonClick?.()}>
          {Strings.Button.UnPublish}
        </Button>
      )}
      <Button
        variant="primary"
        type="submit"
        onClick={() => {
          if (shiftDetails?.shiftId) {
            navigate(
              caId
                ? AdminRoutePath.SCHAssignCAByCAId.replace(':shiftId', shiftDetails?.shiftId.toString())
                    .replace(':type', Number(type).toString())
                    .replace(':caId', Number(caId).toString())
                : AdminRoutePath.SCHAssignCA.replace(':shiftId', shiftDetails?.shiftId.toString()).replace(':type', Number(type).toString()),
            );
          }
        }}
      >
        {Strings.Shift.Button.Assign}
      </Button>
    </>
  );

  // To render the buttons
  const renderButtons = () => {
    if (Number(role) === RoleTypes.Scheduler) {
      // To show Back button for Scheduler role
      return (
        <>
          <Button
            variant="outline-primary"
            type="submit"
            onClick={() => navigate(type === ScheduleTabs.MySchedule ? AdminRoutePath.SCHViewSchedules : AdminRoutePath.ManageSchedule)}
          >
            {Strings.Button.Back}
          </Button>
          {Number(caListCount) > NUMBER_0 && (
            <Button variant="outline-primary" type="submit" onClick={handleSaveClick}>
              {Strings.Button.Save}
            </Button>
          )}
          {/* Render other buttons based on shift conditions */}
          {renderSchedulerButtons()}{' '}
        </>
      );
    }

    // Render buttons for CA role based on shift conditions
    if (isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftToTime || '') && Number(role) === RoleTypes.CA) {
      return renderCAButtons();
    }

    return null;
  };

  // Render shift CA count informations.
  const renderShiftRequiredAndAssignedCountData = (shiftInfo: IShift) => (
    <>
      <Col xs={5} lg={4} xl={4} xxl={3} className={shiftInfo.scheduleId && shiftInfo.scheduleId > 0 ? '' : 'border-bottom-lg'}>
        {Strings.Schedule.CASlots}
        <span>
          {' '}
          {shiftDetails?.assignedCACount}/{shiftDetails?.totalCACount}
        </span>
      </Col>
      {Number(role) === RoleTypes.Scheduler && (
        <Col xs={7} lg={4} xl={4} xxl={3} className={`openslot-main ${shiftInfo.scheduleId && shiftInfo.scheduleId > 0 ? '' : 'border-active-bottom-md border-none-bottom-md'}`}>
          {Strings.Schedule.OpenSlot}
          <label htmlFor="Open slot">{renderOpenSlots()}</label>
        </Col>
      )}
      {shiftInfo?.lightDutyRequired > NUMBER_0 && Number(role) === RoleTypes.Scheduler && (
        <Col xs={6} lg={4} xl={4} xxl={3} className="border-0">
          {Strings.Schedule.LDOpenslot}
          <span>
            {shiftDetails?.lightDutyCount}/{shiftDetails?.lightDutyRequired}
          </span>
        </Col>
      )}
      {shiftInfo?.lightDutyRequired > NUMBER_0 && shiftInfo?.lightDutyCount < shiftInfo?.lightDutyRequired && Number(role) === RoleTypes.CA && isLd && (
        <Col xs={6} lg={4} xl={4} xxl={3} className="border-0">
          {Strings.Schedule.LDOpenslot}
          <span>{Strings.Label.Yes}</span>
        </Col>
      )}
      {shiftInfo?.onCallRequired > NUMBER_0 && Number(role) === RoleTypes.Scheduler && (
        <Col xs={6} lg={4} xl={4} xxl={3} className={shiftInfo.scheduleId && shiftInfo.scheduleId > 0 ? '' : 'border-0'}>
          {Strings.Label.OnCallSlots}
          <span>
            {shiftDetails?.onCallTotal}/{shiftDetails?.onCallRequired}
          </span>
        </Col>
      )}
      {shiftInfo?.onCallRequired > NUMBER_0 && shiftInfo?.onCallTotal < shiftInfo?.onCallRequired && Number(role) === RoleTypes.CA && (
        <Col xs={6} lg={4} xl={4} xxl={3} className={shiftInfo.scheduleId && shiftInfo.scheduleId > 0 ? '' : 'border-0'}>
          {Strings.Label.OnCallSlots}
          <span>{Strings.Label.Yes}</span>
        </Col>
      )}
    </>
  );

  // Render CA user additional shift information when accessing details page from My schedule section.
  const renderCAShiftAdditionalInfo = (shiftInfo: IShift) => (
    <>
      <Col xs={6} lg={4} xl={4} xxl={3} className="border-top-one">
        {Strings.Schedule.AssignedBy}
        <span>{shiftInfo.assignedBy}</span>
      </Col>
      <Col xs={6} lg={4} xl={4} xxl={3} className="border-top-one">
        {Strings.Schedule.AssignedOn}
        <span>{shiftInfo.assignedOn && moment(shiftInfo.assignedOn).format(DATE_FORMAT_MM_DD_YYYY)}</span>
      </Col>
      <Col xs={6} lg={4} xl={4} xxl={3} className="border-top-one border-bottom-0">
        {Strings.Schedule.WorkShowType}
        <span>{getEnumKeyByValue(NoShow, shiftInfo.noShowType)}</span>
      </Col>
      <Col xs={6} lg={4} xl={4} xxl={3} className="border-top-one">
        {Strings.Schedule.WorkHours}
        <span>{shiftInfo.workHours ? convertFloatToTime(shiftInfo.workHours) + ' Hrs' : ''}</span>
      </Col>
    </>
  );

  return (
    <Col className="shiftcard-main shiftdetails-card">
      {shiftDetails ? (
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Col className="d-flex align-items-center justify-content-between">
              <div>
                {DAY_SHIFTS.includes(shiftDetails.shortName) && <DayShift />}
                {NIGHT_SHIFTS.includes(shiftDetails.shortName) && <NightShift />}
                {MID_SHIFTS.includes(shiftDetails.shortName) && <MidShift />}
                {shiftDetails.shiftName}
              </div>
              <div className="cardcell-for-mob">Shift Code: {shiftDetails.shortName}</div>
            </Col>
          </Card.Header>
          <Card.Body className="shift-details">
            <Col className="shift-details-sub">
              <Row>
                <Col xs={6} lg={4} xl={4} xxl={3} className="cardcell-for-web">
                  {Strings.Schedule.ShiftCode}
                  <span>{shiftDetails.shortName}</span>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3} className="d-none">
                  <p>do not delete- its for responsive purpose</p>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3}>
                  {Strings.Schedule.ShiftStart}
                  <span>{moment.utc(shiftDetails.shiftFromTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)}</span>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3}>
                  {Strings.Schedule.ShiftEnd}
                  <span>{moment.utc(shiftDetails.shiftToTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)}</span>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3} className="cardcell-for-web">
                  {Strings.Label.Status}
                  <div className="status">
                    {shiftDetails.published && (
                      <span className="published">
                        <Published /> {Strings.Filter.Published}
                      </span>
                    )}
                    {!shiftDetails.published && (
                      <span className="draft">
                        <Draft /> {Strings.Filter.Draft}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3} className="d-none">
                  <p>do not delete- its for responsive purpose</p>
                </Col>
                {renderShiftRequiredAndAssignedCountData(shiftDetails)}
                {type === ScheduleTabs.MySchedule && renderCAShiftAdditionalInfo(shiftDetails)}
              </Row>
            </Col>
            <Row>
              <Col className="d-flex align-items-center">
                <div className="status cardcell-for-mob">
                  {shiftDetails.published ? (
                    <span className="published">
                      <Published /> {Strings.Filter.Published}
                    </span>
                  ) : (
                    ''
                  )}
                  {!shiftDetails.published ? (
                    <span className="draft">
                      <Draft /> {Strings.Filter.Draft}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Col>

              <Col xs="auto">{renderButtons()}</Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <>No data found.</>
      )}
    </Col>
  );
};
export default ShiftDetailsCard;
