/**
 * @file   src\components\EmployeeCard.tsx
 * @brief  Employee details card.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import DateTimePicker from 'react-datetime-picker';
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, Button, useAccordionButton, useNavigate, useLocation, useState, useEffect, moment } from './ThirdPartyComponents';
import { CustomToggleProps, IEmployeeCard, IObject } from '../interfaces/generalInterface';
import KKICheckbox from './KKICheckbox';
import Arrow from '../assests/icons/DownArrow';
import Time from '../assests/icons/Time';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import {
  DATE_FORMAT_YYYY_MM_DD_HH_MM_SS,
  DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z,
  NUMBER_0,
  NUMBER_1,
  NUMBER_2,
  NUMBER_300,
  NUMBER_3,
  NUMBER_4,
  NUMBER_40,
  STORAGE_USER,
} from '../utils/constants';
import { ColorGroups, MyScheduleActions, NoShow, AdminRoutePath, NavigationToEmployeeCardFrom, CAListTypes } from '../utils/enums';
import KKISelect from './KKISelect';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { calculateHourDifference, convertToDbTime, formatPhoneNumber, getDataFromStorage, getKeyIndicators, isCurrentTimeIsBeforeShiftStartTime } from '../utils/helper';
import {
  CHECKIN_TIME_MUST_AFTER_SHIFT_START_TIME,
  CHECKIN_TIME_MUST_BEFORE_CHECKOUT_TIME,
  CHECKOUT_TIME_MUST_AFTER_CHECKIN_TIME,
  CHECKOUT_TIME_MUST_BEFORE_SHIFT_END_TIME,
} from '../messages/validationMessages';
import KKIInput from './KKIInput';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};
// NoShow status options
const show = [
  { label: NoShow[NoShow.Show].replace(/_/g, ' '), value: NoShow.Show.toString() },
  { label: NoShow[NoShow.No_Show].replace(/_/g, ' '), value: NoShow.No_Show.toString() },
  { label: NoShow[NoShow.Left_Early].replace(/_/g, ' '), value: NoShow.Left_Early.toString() },
  { label: NoShow[NoShow.Show_Late].replace(/_/g, ' '), value: NoShow.Show_Late.toString() },
];

const EmployeeCard = ({
  caDetails,
  shiftDetails,
  isFrom, // 1= from details page, 2= from assign page, 3 = callout page
  preferredType,
  onCheckboxChange,
  onButtonClick,
  onNoShowChange,
  onOnCallChange,
  selectedEmployees,
  isSelectAllChecked,
}: IEmployeeCard) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Create location object to access location info.
  const location = useLocation();

  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  // Access userInfo from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  // Loggined user role
  const roleId = userInfo?.roleId;

  // Initialize component stat variables.
  const [selectedShow, setSelectedShow] = useState<IObject | null>(null);
  const [onCallValues, setOnCallValues] = useState<boolean>(false);
  const [checkInTime, setCheckInTime] = useState<Date | null>(null);
  const [checkOutTime, setCheckOutTime] = useState<Date | null>(null);
  const [checkInErrorMessage, setCheckInErrorMessage] = useState<string>('');
  const [checkOutErrorMessage, setCheckOutErrorMessage] = useState<string>('');
  const [attempt1, setAttempt1] = useState<Date | null>(null);
  const [attempt2, setAttempt2] = useState<Date | null>(null);
  const [note, setNote] = useState<string>('');

  // Setting the initial NoShow value based on the employee's details.
  useEffect(() => {
    const selectedShowValue = show.find((option) => option.value === caDetails?.isNoShow?.toString()) || null;
    setSelectedShow(selectedShowValue);
    setOnCallValues(caDetails?.isOnCall || false);
    const parsedCheckInTime = caDetails?.checkInTime ? convertToDbTime(caDetails?.checkInTime) : shiftDetails?.shiftFromTime ? convertToDbTime(shiftDetails?.shiftFromTime) : null;
    const parsedCheckOutTime = caDetails?.checkOutTime ? convertToDbTime(caDetails?.checkOutTime) : shiftDetails?.shiftToTime ? convertToDbTime(shiftDetails?.shiftToTime) : null;
    setCheckInTime(parsedCheckInTime);
    setCheckOutTime(parsedCheckOutTime);
    if (caDetails?.attemptOne && caDetails?.attemptTwo) {
      const parsedAttempt1 = convertToDbTime(caDetails?.attemptOne);
      const parsedAttemp2 = convertToDbTime(caDetails?.attemptTwo);
      setAttempt1(parsedAttempt1);
      setAttempt2(parsedAttemp2);
    }
    setNote(caDetails?.note || '');
    setCheckInErrorMessage('');
    setCheckOutErrorMessage('');
  }, [caDetails]);

  // Handle checkbox change event and notify the parent component
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheckboxChange) {
      onCheckboxChange(e.target.checked, caDetails);
    }
  };

  // Method to handle checkin and out time.
  const handleCheckInOutTimes = (noShowStatus: number) => {
    const shouldUseCADetails = caDetails?.checkInTime && noShowStatus === caDetails?.isNoShow;
    let checkInTimeToSet = null;
    if (shouldUseCADetails) {
      checkInTimeToSet = convertToDbTime(caDetails.checkInTime || '');
    } else if (shiftDetails?.shiftFromTime) {
      checkInTimeToSet = convertToDbTime(shiftDetails.shiftFromTime);
    }
    setCheckInTime(checkInTimeToSet);

    let checkOutTimeToSet = null;
    if (shouldUseCADetails) {
      checkOutTimeToSet = convertToDbTime(caDetails.checkOutTime || '');
    } else if (shiftDetails?.shiftToTime) {
      checkOutTimeToSet = convertToDbTime(shiftDetails.shiftToTime);
    }
    setCheckInTime(checkOutTimeToSet);
  };

  // Method to handle no show attempts.
  const handleAttemptTimes = () => {
    setAttempt1(caDetails?.attemptOne ? convertToDbTime(caDetails.attemptOne) : null);
    setAttempt2(caDetails?.attemptTwo ? convertToDbTime(caDetails.attemptTwo) : null);
  };

  // Method to trigger no show card changes.
  const triggerNoShowChange = (noShowStatus: number) => {
    onNoShowChange?.({
      caId: Number(caDetails.caId),
      noShowStatus,
      noShowHour: NUMBER_0.toString(),
      checkInTime: null,
      checkOutTime: null,
      attemptOne: null,
      attemptTwo: null,
      note,
    });
  };

  // NoShow status change event hander.
  const handleNoShowStatusChange = (val: IObject) => {
    const noShowStatus = val.value;
    const selectedOption = show.find((option) => option.value === noShowStatus?.toString()) || null;
    setSelectedShow(selectedOption);
    if (Number(noShowStatus) !== NoShow.No_Show) {
      handleCheckInOutTimes(Number(noShowStatus));
    } else {
      handleAttemptTimes();
    }
    setCheckInErrorMessage('');
    setCheckOutErrorMessage('');
    if (caDetails?.caId && onNoShowChange) {
      triggerNoShowChange(Number(noShowStatus));
    }
  };

  // OnCall checkbox change event handler
  const handleOnCallChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnCallValues(e.target.checked);
    if (caDetails?.caId && onOnCallChange) {
      onOnCallChange(Number(caDetails?.caId), e.target.checked, Number(caDetails?.colorGroup));
    }
  };

  // Handle check-in time change
  const handleCheckInChange = (value: Date | null) => {
    if (value) {
      setCheckInErrorMessage('');
      const shiftTime = moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const enteredTime = moment(value).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const shiftEndTime = moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      if (moment(enteredTime).isSameOrAfter(moment(shiftTime)) && moment(enteredTime).isSameOrBefore(moment(shiftEndTime))) {
        if (checkOutTime && moment(enteredTime).isAfter(moment(checkOutTime))) {
          setCheckInErrorMessage(CHECKIN_TIME_MUST_BEFORE_CHECKOUT_TIME);
          setCheckOutErrorMessage('');
        } else {
          setCheckInErrorMessage('');
          setCheckOutErrorMessage('');
        }
      } else {
        setCheckInErrorMessage(CHECKIN_TIME_MUST_AFTER_SHIFT_START_TIME);
      }
      setCheckInTime(value);
    } else {
      setCheckInTime(null);
    }
    if (caDetails?.caId && selectedShow && onNoShowChange) {
      const hourDiff = calculateHourDifference(value, checkOutTime);
      onNoShowChange?.({
        caId: Number(caDetails.caId),
        noShowStatus: Number(selectedShow.value),
        noShowHour: hourDiff,
        checkInTime: moment(value).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        checkOutTime: moment(checkOutTime).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        attemptOne: null,
        attemptTwo: null,
        note,
      });
    }
  };

  // Handle check-out time change
  const handleCheckOutChange = (value: Date | null) => {
    if (value) {
      setCheckOutErrorMessage('');
      const shiftTime = moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const enteredTime = moment(value).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      const shiftEndTime = moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS);
      if (moment(enteredTime).isSameOrAfter(moment(shiftTime)) && moment(enteredTime).isSameOrBefore(moment(shiftEndTime))) {
        if (checkInTime && moment(enteredTime).isBefore(moment(checkInTime))) {
          setCheckOutErrorMessage(CHECKOUT_TIME_MUST_AFTER_CHECKIN_TIME);
          setCheckInErrorMessage('');
        } else {
          setCheckOutErrorMessage('');
          setCheckInErrorMessage('');
        }
      } else {
        setCheckOutErrorMessage(CHECKOUT_TIME_MUST_BEFORE_SHIFT_END_TIME);
      }
      setCheckOutTime(value);
    } else {
      setCheckOutTime(null);
    }
    if (caDetails?.caId && selectedShow && onNoShowChange) {
      const hourDiff = calculateHourDifference(checkInTime, value);
      onNoShowChange?.({
        caId: Number(caDetails.caId),
        noShowStatus: Number(selectedShow.value),
        noShowHour: hourDiff,
        checkInTime: moment(checkInTime).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        checkOutTime: moment(value).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        attemptOne: null,
        attemptTwo: null,
        note,
      });
    }
  };

  // Handle no show contact attempt1 change handler.
  const handleNoShowAttempt1Change = (value: Date | null) => {
    if (value) {
      setAttempt1(value);
    }
    if (caDetails?.caId && selectedShow && onNoShowChange) {
      onNoShowChange?.({
        caId: Number(caDetails.caId),
        noShowStatus: Number(selectedShow.value),
        noShowHour: '',
        checkInTime: null,
        checkOutTime: null,
        attemptOne: moment(value).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        attemptTwo: moment(attempt2).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        note,
      });
    }
  };

  // Handle no show contact attempt2 change handler.
  const handleNoShowAttempt2Change = (value: Date | null) => {
    if (value) {
      setAttempt2(value);
    }
    if (caDetails?.caId && selectedShow && onNoShowChange) {
      onNoShowChange?.({
        caId: Number(caDetails.caId),
        noShowStatus: Number(selectedShow.value),
        noShowHour: '',
        checkInTime: null,
        checkOutTime: null,
        attemptOne: moment(attempt1).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        attemptTwo: moment(value).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z),
        note,
      });
    }
  };

  // Handle comments input field changes
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNote(value);
    if (caDetails?.caId && selectedShow && onNoShowChange) {
      onNoShowChange?.({
        caId: Number(caDetails.caId),
        noShowStatus: Number(selectedShow.value),
        noShowHour: '',
        checkInTime: Number(selectedShow?.value) !== NoShow.No_Show ? moment(checkInTime).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z) : null,
        checkOutTime: Number(selectedShow?.value) !== NoShow.No_Show ? moment(checkOutTime).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z) : null,
        attemptOne: Number(selectedShow?.value) === NoShow.No_Show ? moment(attempt1).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z) : null,
        attemptTwo: Number(selectedShow?.value) === NoShow.No_Show ? moment(attempt2).format(DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z) : null,
        note: value,
      });
    }
  };

  // Method to determine on-call and mandatory OT status
  const getOnCallAndMandatoryOTStatus = (callOutType: number | undefined) => {
    let isOnCall = false;
    let isMandatoryOT = false;
    const type = callOutType ?? NUMBER_0;
    switch (type) {
      case NUMBER_3:
        isOnCall = true;
        break;
      case NUMBER_4:
        isMandatoryOT = true;
        break;
      default:
        break;
    }

    return { isOnCall, isMandatoryOT };
  };

  // Get the on-call and mandatory OT status.
  const { isOnCall, isMandatoryOT } = getOnCallAndMandatoryOTStatus(shiftDetails?.callOutType);

  // Render CA work hours.
  const renderCAWorkHours = () => {
    if (isFrom !== NavigationToEmployeeCardFrom.CA_Assign_Page && isFrom !== NavigationToEmployeeCardFrom.Callout_Page) return <></>;
    const hours =
      isFrom === NavigationToEmployeeCardFrom.Callout_Page || preferredType === Number(CAListTypes.PREFERRED_CA_LIST)
        ? caDetails?.workHours ?? NUMBER_0
        : caDetails?.actualHours ?? NUMBER_0;
    return (
      <div className="fw-sb">
        {Strings.Schedule.WorkHours}: {parseFloat(hours.toFixed(2))} Hrs
      </div>
    );
  };

  // Render employee card header data.
  const renderEmployeeCardHeader = () => (
    <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
      <Col className="d-flex align-items-center">
        {(isFrom === NavigationToEmployeeCardFrom.CA_Assign_Page && roleId === NUMBER_2) || isFrom === NavigationToEmployeeCardFrom.Callout_Page ? (
          <div className="me-2 position-relative z-1">
            <KKICheckbox
              id={`checkbox-${caDetails?.caId.toString()}`}
              name="kkicheckbox"
              checkBoxType="checkbox"
              checked={isSelectAllChecked || selectedEmployees?.find((employee) => employee?.caId === caDetails?.caId) !== undefined}
              onChange={handleCheckboxChange}
            />
          </div>
        ) : (
          ''
        )}
        {caDetails?.firstName || caDetails?.lastName ? `${caDetails?.firstName ?? ''} ${caDetails?.lastName ?? ''}`.trim() : null}
        {isFrom === NavigationToEmployeeCardFrom.Shift_Details_Page && caDetails?.replacement && (
          <span className="replacementca text-mediumred">{Strings.Label.ReplaceCALabel}</span>
        )}
      </Col>
      <div>
        <Col xs="auto" className="d-flex cardheader-right align-items-center">
          {isFrom === NavigationToEmployeeCardFrom.Shift_Details_Page && (shiftDetails?.onCallRequired ?? NUMBER_0) > NUMBER_0 ? (
            <div className="ms-2 position-relative z-1 fw-sb">
              <KKICheckbox id={caDetails?.caId.toString()} name="kkicheckbox" checkBoxType="checkbox" label="On Call" checked={onCallValues} onChange={handleOnCallChange} />
            </div>
          ) : (
            ''
          )}
          {renderCAWorkHours()}
        </Col>
        <CustomToggle eventKey="0" callback={toggleCollapse}>
          <Arrow />
        </CustomToggle>
      </div>
    </Card.Header>
  );

  // Render employee card action buttons.
  const renderEmployeeCardActionButton = () => (
    <Col xs={12} md="auto" className={isFrom !== NUMBER_1 ? 'd-none' : 'text-end'}>
      <Button
        variant="outline-primary"
        type="submit"
        onClick={() => navigate({ pathname: `${AdminRoutePath.SCHViewSchedules}/${caDetails.caId}` }, { state: { prevUrl: location.pathname } })}
      >
        {Strings.Button.ViewSchedules}
      </Button>
      <Button variant="primary" type="submit" onClick={() => onButtonClick?.(caDetails?.caId, MyScheduleActions.Unassign)}>
        {Strings.Button.UnAssign}
      </Button>

      {shiftDetails?.published && isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftToTime || '') ? (
        <Button variant="primary" type="submit" onClick={() => onButtonClick?.(caDetails?.caId, MyScheduleActions.Callout)}>
          {Strings.Shift.Button.CallOut}
        </Button>
      ) : (
        ''
      )}
    </Col>
  );

  // Method to render CA shift information when redirecting from callout list page.
  const renderCallOutCAInfo = () => (
    <>
      <Col xs={6} lg={6} xl={6} xxl={4} className={`mt-min-xxl-16 pt-min-xxl-16 bt-min-xxl ${isFrom === NavigationToEmployeeCardFrom.Callout_Page ? 'card-cell-mob' : ''}`}>
        <span className="lg-ps-2">{Strings.Label.OnCall}</span>
        <span>{isOnCall ? Strings.Label.Yes : Strings.Label.No}</span>
      </Col>
      <Col xs={6} lg={6} xl={6} xxl={4} className="mt-min-xxl-16 pt-min-xxl-16 bt-min-xxl">
        <span className="lg-ps-2">{Strings.Label.MandatoryOT}</span>
        <span>{isMandatoryOT ? Strings.Label.Yes : Strings.Label.No}</span>
      </Col>
      <Col xs={6} lg={6} xl={6} xxl={4} className="mt-min-xxl-16 pt-min-xxl-16 bt-min-xxl">
        <span className="lg-ps-2">{Strings.CAKeyIndicators.OnPremise}</span>
        <span>{caDetails?.onPrem ? Strings.Label.Yes : Strings.Label.No}</span>
      </Col>
    </>
  );

  return (
    <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main">
      <Card className={isFrom === NavigationToEmployeeCardFrom.CA_Assign_Page && caDetails?.assignedHours > NUMBER_40 ? 'time-exceed overflow-visible' : 'overflow-visible'}>
        {renderEmployeeCardHeader()}
        <Accordion.Collapse eventKey="0">
          <Card.Body className="shift-details">
            <Col className="shift-details-sub">
              {shiftDetails &&
                shiftDetails?.published &&
                isFrom === NavigationToEmployeeCardFrom.Shift_Details_Page &&
                !isCurrentTimeIsBeforeShiftStartTime(shiftDetails?.shiftFromTime || '') && (
                  <>
                    <Row className="mb-3">
                      <Col className="fw-normal">
                        <KKISelect
                          id="Status"
                          label="Attendance Type"
                          name="Status"
                          className="custom-select"
                          placeholder={Strings.Label.CallOutCategory}
                          searchvalue={false}
                          options={show}
                          value={selectedShow}
                          onSelectChange={handleNoShowStatusChange}
                        />
                      </Col>
                      {Number(selectedShow?.value) !== NoShow.No_Show && (
                        <Col xl={4} className="date-time">
                          <label htmlFor="timing" className="form-label fw-normal">
                            {Strings.Label.CheckInTime}
                            <input type="hidden" id="timing" />
                          </label>
                          <DateTimePicker
                            id="checkInTime"
                            calendarIcon={
                              <Button variant="Link" onClick={(e) => e.stopPropagation()}>
                                <Time />
                              </Button>
                            }
                            disableClock
                            value={checkInTime}
                            onChange={handleCheckInChange}
                            disabled={Number(selectedShow?.value) === NoShow.No_Show || !selectedShow?.value}
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                          />
                          {checkInErrorMessage && <span className="error">{checkInErrorMessage}</span>}
                        </Col>
                      )}
                      {Number(selectedShow?.value) !== NoShow.No_Show && (
                        <Col className="date-time">
                          <label htmlFor="timing" className="form-label fw-normal">
                            {Strings.Label.CheckOutTime}
                            <input type="hidden" id="timing" />
                          </label>
                          <DateTimePicker
                            id="checkOutTime"
                            calendarIcon={
                              <Button variant="Link" onClick={(e) => e.stopPropagation()}>
                                <Time />
                              </Button>
                            }
                            disableClock
                            value={checkOutTime}
                            onChange={handleCheckOutChange}
                            disabled={Number(selectedShow?.value) === NoShow.No_Show || !selectedShow?.value}
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                          />
                          {checkOutErrorMessage && <span className="error">{checkOutErrorMessage}</span>}
                        </Col>
                      )}
                      {Number(selectedShow?.value) === NoShow.No_Show && (
                        <Col xl={4} className="date-time">
                          <label htmlFor="timing" className="form-label fw-normal">
                            {Strings.Label.AttemptOne}
                            <input type="hidden" id="timing" />
                          </label>
                          <DateTimePicker
                            id="checkInTime"
                            calendarIcon={
                              <Button variant="Link" onClick={(e) => e.stopPropagation()}>
                                <Time />
                              </Button>
                            }
                            disableClock
                            value={attempt1}
                            onChange={handleNoShowAttempt1Change}
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                          />
                        </Col>
                      )}
                      {Number(selectedShow?.value) === NoShow.No_Show && (
                        <Col className="date-time">
                          <label htmlFor="timing" className="form-label fw-normal">
                            {Strings.Label.AttemptTwo}
                            <input type="hidden" id="timing" />
                          </label>
                          <DateTimePicker
                            id="checkOutTime"
                            calendarIcon={
                              <Button variant="Link" onClick={(e) => e.stopPropagation()}>
                                <Time />
                              </Button>
                            }
                            disableClock
                            value={attempt2}
                            onChange={handleNoShowAttempt2Change}
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                          />
                        </Col>
                      )}
                    </Row>
                    <Row className="mb-3">
                      <Col className="fw-normal">
                        <KKIInput
                          id="note"
                          label={Strings.Label.Note}
                          name="lastName"
                          type="text"
                          as="textarea"
                          rows={NUMBER_2}
                          value={note}
                          onChange={onInputChange}
                          maxLength={NUMBER_300}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              <Row>
                <Col xs={6} lg={6} xl={6} xxl={4} className="card-cell-mob">
                  {Strings.Label.EmpId}: <span>{caDetails?.empId}</span>
                </Col>
                <Col xs={6} lg={6} xl={6} xxl={4} className="card-cell-mob">
                  <span className="lg-ps-2">{Strings.Label.ColorGroup}</span>
                  <span>{ColorGroups[caDetails?.colorGroup]}</span>
                </Col>
                <Col xs={6} lg={6} xl={6} xxl={4} className={isFrom === NavigationToEmployeeCardFrom.Callout_Page ? 'card-cell-mob' : ''}>
                  <span className="lg-ps-2">{Strings.Label.Contact}</span>
                  <span>{caDetails?.phone ? formatPhoneNumber(caDetails?.phone) : ''}</span>
                </Col>
                {isFrom === NavigationToEmployeeCardFrom.Callout_Page && renderCallOutCAInfo()}
              </Row>
            </Col>
            <Row className="align-items-center">
              <Col xs={12} md>
                {Strings.Label.KeyIndicators}:
                <span className="fw-sb">
                  {' '}
                  {getKeyIndicators({
                    floatStaff: caDetails?.floatStaff,
                    fullTime: caDetails?.fullTime,
                    lighDuty: caDetails?.lightDuty,
                    volunteer: caDetails?.volunteer,
                    student: caDetails?.student,
                    restrictedOt: caDetails?.restrictedOt,
                    partTime16: caDetails?.partTime16,
                    partTime20: caDetails?.partTime20,
                    partTime24: caDetails?.partTime24,
                  })}
                </span>
              </Col>
              {renderEmployeeCardActionButton()}
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default EmployeeCard;
