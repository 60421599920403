/**
 * @file   src\store\actions\manageCAAction.ts
 * @brief  This file is responsible for creating asynchronous api call for CA user management slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { ICADetailsUpdateRequest, ICAListRequest, ICAStatusUpdateRequest, IMOTResetRequest } from '../../interfaces/caInterface';
import axios from '../../utils/apiConfig';
import { ManageCAUsersApi, KKIActionTypes } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';

// Get all CA users api call.
export const getAllCAUsers = createAsyncThunk('/manageCA/getAllCAUsers', async (request: ICAListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageCAUsersApi.GET_ALL_CA_USERS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update CA user status api call.
export const activateOrDeactivateCA = createAsyncThunk('/manageCA/activateOrDeactivateCA', async (request: ICAStatusUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageCAUsersApi.ACTIVATE_OR_INACATIVATE_CA_USERS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get CA details api call.
export const getCADetails = createAsyncThunk('/manageCA/getCADetails', async (userId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageCAUsersApi.GET_CA_DETAILS}/${userId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update CA details api call.
export const updateCADetails = createAsyncThunk('/manageCA/updateCADetails', async (request: ICADetailsUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageCAUsersApi.UPDATE_CA_DETAILS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Reset MOT position api call.
export const resetMOTPosition = createAsyncThunk('/manageCA/resetMOTPosition', async (request: IMOTResetRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageCAUsersApi.RESET_MOT_POSITION, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all manage CA states to initial sate action.
export const revertAllManageCAState = createAction(KKIActionTypes.REVERT_ALL_MANAGE_CA_STATE);
