/**
 * @file   src\components\KKIInput.ts
 * @brief  Reusable input component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { React, Form } from './ThirdPartyComponents';
import '../styles/KKIPassword.scss';
import { KKIInputPrp } from '../interfaces/generalInterface';

const KKIPassword = ({ id, name, type, placeholder, onChange, value, onKeyDown, alert, icon, showpass, label, mandatory, autocomplete, maxLength }: KKIInputPrp) => {
  return (
    <>
      <label htmlFor="passwordlabel" className="form-label">
        {label}
        {mandatory ? <span className="text-red ms-1">*</span> : ''}
      </label>
      <Form.Group className="kki-password position-relative">
        <Form.Control
          id={id}
          className={alert && alert !== '' ? 'form-control error' : 'form-control'}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          autoComplete={autocomplete || 'off'}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
        />
        <span className="icon-wrap">{icon}</span>
        <span className="icon-pass">{showpass}</span>

        {alert && alert !== '' && <span className="error">{alert}</span>}
      </Form.Group>
    </>
  );
};
export default KKIPassword;
