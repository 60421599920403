/**
 * @file   src\messages\validationMessages.ts
 * @brief  This file is responsible for defining validation message constants.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

export const EMAIL_INVALID = 'Enter a valid Email address.';
export const MAXLENGTH_EXCEEDED = 'A maximum of 200 characters are only allowed.';
export const EMAIL_REQUIRED = 'Enter Email address.';
export const PASSWORD_REQUIRED = 'Enter Password.';
export const CONFIRM_PASSWORD_REQUIRED = 'Enter Confirm Password.';
export const ROLE_REQUIRED = 'Select a Role.';
export const MOBILE_REQUIRED = 'Enter Mobile Number.';
export const MOBILE_INVALID = 'Enter a valid Mobile Number.';
export const PASSWORD_MATCH = 'New Password and Confirm New Password entries must be the same.';
export const PASSWORD_STRENGTH = 'Password must contain a maximum of 8 characters, including one uppercase letter, one lowercase letter,  one number and a special character.';

export const PASSWORD_LOWERCASE_LETTER = 'Password must contain at least one lowercase letter';
export const PASSWORD_UPPERCASE_LETTER = 'Password must contain at least one uppercase letter';
export const PASSWORD_NUMBER = 'Password must contain at least one number';
export const PASSWORD_SPECIAL_CHARACTER = 'Password must contain at least one special character';

export const CA_STATUS_REQUIRED = 'Select Status.';
export const OTP_REQUIRED = 'Enter OTP.';
export const OLD_PASSWORD_REQUIRED = 'Enter Old Password.';

export const FIRST_NAME_REQUIRED = 'Enter First Name.';
export const LAST_NAME_REQUIRED = 'Enter Last Name.';
export const EMPLOYEE_ID_REQUIRED = 'Enter Employee ID.';
export const HIRE_DATE_REQUIRED = 'Enter Hire Date.';
export const COLOR_GROUP_REQUIRED = 'Select a Color Group.';

export const LEAVE_TYPE_REQUIRED = 'Select Leave Type.';
export const LEAVE_DATE_FROM_REQUIRED = 'Select Leave From Date.';
export const LEAVE_DATE_TO_REQUIRED = 'Select Leave To Date.';
export const LEAVE_DAYS_REQUIRED = 'Enter Leave Days.';
export const LEAVE_REASON_REQUIRED = 'Enter Reason.';

export const CREATESHIFT_SHIFT_REQUIRED = 'Select Shift.';
export const CREATESHIFT_CA_REQUIRED = 'Enter Total CA Required.';
export const CREATESHIFT_YELLOW_REQUIRED = 'Enter Yellow color group count.';
export const CREATESHIFT_BLUE_REQUIRED = 'Enter Blue color group count.';
export const CREATESHIFT_RED_REQUIRED = 'Enter Red color group count.';
export const CREATESHIFT_WHITE_REQUIRED = 'Enter White color group count.';
export const CREATESHIFT_LD_REQUIRED = 'Enter Light Duty group count.';
export const CREATESHIFT_STARTTIME_REQUIRED = 'Enter Shift Start Time.';
export const CREATESHIFT_ENDTIME_REQUIRED = 'Enter Shift End Time.';
export const CREATESHIFT_STARTDATE_REQUIRED = 'Enter Shift Start Date.';
export const CREATESHIFT_ENDDATE_REQUIRED = 'Enter Shift End Date.';
export const CREATESHIFT_SHIFTDAYS_REQUIRED = 'Select at least one recurring shift day.';

export const AT_LEAST_ONE_EMPLOYEE_REQUIRED = 'No employees selected! Please select at least one employee to assign.';
export const AT_LEAST_ONE_SHIFT_REQUIRED = 'Select at least one shift to proceed.';
export const AT_LEAST_ONE_DRAFT_SHIFT_REQUIRED = 'Select at least one unpublished shift to proceed.';
export const AT_LEAST_ONE_PUBLISHED_SHIFT_REQUIRED = 'Select at least one published shift to proceed.';
export const CA_ASSIGNMENT_SUCCESS = 'The selected CAs have been successfully assigned to the shift.';
export const NOTIFY_CA_SUCCESS = 'The CAs have been notified for self-assignment successfully.';
export const PUBLISH_SHIFT_SUCCESS = 'The selected shifts in the draft mode have been published successfully.';
export const EXPORT_PDF_SUCCESS = 'The PDF has been exported successfully.';
export const SEND_MESSAGE_SUCCESS = 'The message has been sent successfully.';
export const APPROVE_SWAP_REQUEST_SUCCESS = 'The swap request has been approved successfully.';

export const HOLIDAY_DATE_REQUIRED = 'Select a Date.';
export const HOLIDAY_NAME_REQUIRED = 'Enter Holiday name.';
export const HOLIDAY_NAME_MIN = 'Holiday name must contain at least 3 characters.';
export const HOLIDAY_NAME_MAX = 'Holiday name must contain maximum of 50 characters.';
export const DELETE_HOLIDAY_SUCCESS = 'The holiday has been deleted.';

export const DECLINE_REASON_REQUIRED = 'Enter Reason.';
export const DECLINE_REASON_TYPE_REQUIRED = 'Select Reason.';

export const ONCALL_ASSIGNMENT_SUCCESS = 'The selected CAs have been saved as On Call successfully.';

export const SHIFT_REQUIRED = 'Select a shift to proceed.';
export const PROFILE_FETCH_FAILD = 'Could not retrieve the profile data. Please try again later.';

export const CA_NAME_REQUIRED = 'Enter CA Name.';
export const LD_REPORTING_DATE_REQUIRED = 'Select Reporting Date.';
export const LD_FOLLOWUP_DATE_REQUIRED = 'Select Follow up Date.';
export const LD_REASON_REQUIRED = 'Enter Reason.';
export const LD_REASON_TYPE_REQUIRED = 'Select Reason.';
export const LD_INJURY_TYPE_REQUIRED = 'Select Injury Type.';
export const SUBSCRIBE_REQUIRED = 'Please agree to the Terms & Conditions.';

export const CHECKIN_TIME_MUST_BEFORE_CHECKOUT_TIME = 'Check-in Time should be earlier than Check-out Time.';
export const CHECKIN_TIME_MUST_AFTER_SHIFT_START_TIME = 'Check-in Time should be between Shift Start and Shift End Time.';
export const CHECKOUT_TIME_MUST_AFTER_CHECKIN_TIME = 'Check-out Time should be later than Check-in Time.';
export const CHECKOUT_TIME_MUST_BEFORE_SHIFT_END_TIME = 'Check-out Time should be between Shift Start and Shift End Time.';

export const CALLOUT_REASON_REQUIRED = 'Enter Reason.';
export const CALLOUT_REASON_TYPE_REQUIRED = 'Select Reason.';
export const DATE_RANGE_VALIDATION_MESSAGE = 'You should select a date range of up to a maximum of 3 months.';

export const REPLACE_CA_CALLOUT_COUNT_EXCEED = 'Replacement CA count cannot exceed the shift call out count({0}).';
