/**
 * @file   src\components\ShiftCard.tsx
 * @brief  Component for listing schedule.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useEffect } from 'react';
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, useAccordionButton, Button, useState, moment } from './ThirdPartyComponents';
import { CustomToggleProps, ISwapCard } from '../interfaces/generalInterface';
import Arrow from '../assests/icons/DownArrow';
import DayShift from '../assests/icons/DayShift';
import NightShift from '../assests/icons/NightShift';
import MidShift from '../assests/icons/MidShift';
import Pending from '../assests/icons/Pending';
import Approved from '../assests/icons/Publish';
import Declined from '../assests/icons/Declined';
import ConfirmationModal from './ConfirmationModal';
import { useAppDispatch } from '../hooks';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import { SwapActions, SwapStatus } from '../utils/enums';
import { DATE_FORMAT_HHMMA, NUMBER_0, NIGHT_SHIFTS, DAY_SHIFTS, MID_SHIFTS, STORAGE_USER, DATE_FORMAT_MM_DD_YYYY_DDD, DATE_FORMAT_MM_DD_YYYY_HMMA_DDD } from '../utils/constants';
import { deleteSwapReuest, resendSwapRequest, updateSwapRequestStatus } from '../store/actions/manageSwapAction';
import { getDataFromStorage, isCurrentTimeIsBeforeShiftStartTime } from '../utils/helper';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};
const SwapCard = ({ myrequest, swapRequests }: ISwapCard) => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access user info from storage.
  const userInfo = getDataFromStorage(STORAGE_USER);

  // Initialize component state variables.
  const [confirmationModalShow, setconfirmationModalShow] = useState<boolean>(false);
  const [swapActionType, setSwapActiontype] = useState<number>(0);

  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  // Function to show the confirmation modal
  const handleShow = () => setconfirmationModalShow(true);

  // Function to hide the confirmation modal
  const handleClose = () => {
    setconfirmationModalShow(false);
    setSwapActiontype(NUMBER_0);
  };

  // wap actions state change side effect handling.
  useEffect(() => {
    if (swapActionType > 0) {
      handleShow();
    }
  }, [swapActionType]);

  // Handle manage swap user actions click.
  const onSwapButtonClickHandler = (action: number) => {
    setSwapActiontype(action);
  };

  // Function to handle confirmation action
  const handleConfirm = () => {
    switch (swapActionType) {
      case SwapActions.Accept:
        dispatch(updateSwapRequestStatus({ swapId: swapRequests.swapRequestId, status: SwapStatus.Pending_Supervisor_Approval }));
        setSwapActiontype(NUMBER_0);
        break;
      case SwapActions.Decline:
        dispatch(updateSwapRequestStatus({ swapId: swapRequests.swapRequestId, status: SwapStatus.Partner_Declined }));
        setSwapActiontype(NUMBER_0);
        break;
      case SwapActions.Delete:
        dispatch(deleteSwapReuest({ swapId: swapRequests.swapRequestId }));
        setSwapActiontype(NUMBER_0);
        break;
      case SwapActions.Resend:
        dispatch(resendSwapRequest({ swapId: swapRequests.swapRequestId }));
        setSwapActiontype(NUMBER_0);
        break;
      default:
        break;
    }
    handleClose();
  };

  // Renders the status text and icon based on the swap request status.
  const renderStatus = (status: number) => {
    switch (status) {
      case SwapStatus.Pending_Partner_Approval:
        return (
          <>
            {SwapStatus[SwapStatus.Pending_Partner_Approval].replaceAll(/_/g, ' ')} <Pending />
          </>
        );
      case SwapStatus.Partner_Declined:
        return (
          <>
            {SwapStatus[SwapStatus.Partner_Declined].replaceAll(/_/g, ' ')} <Declined />
          </>
        );
      case SwapStatus.Pending_Supervisor_Approval:
        return (
          <>
            {SwapStatus[SwapStatus.Pending_Supervisor_Approval].replaceAll(/_/g, ' ')} <Pending />
          </>
        );
      case SwapStatus.Supervisor_Approved:
        return (
          <>
            {SwapStatus[SwapStatus.Supervisor_Approved].replaceAll(/_/g, ' ')} <Approved />
          </>
        );
      case SwapStatus.Supervisor_Declined:
        return (
          <>
            {SwapStatus[SwapStatus.Supervisor_Declined].replaceAll(/_/g, ' ')} <Declined />
          </>
        );
      default:
        return '';
    }
  };

  // Method to render swap request card headers.
  const renderSwapCardHeaders = () => {
    const shiftName = myrequest ? swapRequests.requestedShiftShortName : swapRequests.shiftShortName;
    return (
      <Col className="d-flex align-items-center">
        {DAY_SHIFTS.includes(shiftName) ? <DayShift /> : ''}
        {NIGHT_SHIFTS.includes(shiftName) ? <NightShift /> : ''}
        {MID_SHIFTS.includes(shiftName) ? <MidShift /> : ''}
        {`${swapRequests.requestedShiftName} (${swapRequests.requestShiftShortName})`} -
        <div>
          <label htmlFor="shift dateand time">{moment.utc(swapRequests.requestedShiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_DDD)}</label>
        </div>
      </Col>
    );
  };

  // Method to render swap request information based on incoming/my request.
  const renderSwapCardBodyContent = () => (
    <>
      <Col className="shift-details-sub">
        <Row>
          <Col>
            {myrequest ? Strings.Shift.RequestedShiftTimings : Strings.Shift.MyShiftTimings}
            <span>
              {NIGHT_SHIFTS.includes(swapRequests.requestShiftShortName)
                ? `${moment.utc(swapRequests.requestedShiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment
                    .utc(swapRequests.requestedShiftEndDateTime)
                    .format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)}`
                : `${moment.utc(swapRequests.requestedShiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment
                    .utc(swapRequests.requestedShiftEndDateTime)
                    .format(DATE_FORMAT_HHMMA)}`}
            </span>
          </Col>
        </Row>
      </Col>
      <Col className="shift-details-sub">
        <Row>
          <Col xs={12} lg={6} xl={6} xxl className="border-bottom-sm">
            <span className="lg-ps-2">{myrequest ? Strings.Shift.MyShift : Strings.Shift.OfferedShift}</span>
            <span>{`${swapRequests.shiftName} (${swapRequests.shiftShortName})`}</span>
          </Col>
          <Col xs={12} lg={6} xl={6} xxl>
            <div className="text-start">
              <span className="lg-ps-2 d-block">{myrequest ? Strings.Shift.MyShiftTiming : Strings.Shift.ShiftTiming}</span>
              <span>
                {NIGHT_SHIFTS.includes(swapRequests.shiftShortName)
                  ? `${moment.utc(swapRequests?.shiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment
                      .utc(swapRequests?.shiftEndDateTime)
                      .format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)}`
                  : `${moment.utc(swapRequests?.shiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment
                      .utc(swapRequests?.shiftEndDateTime)
                      .format(DATE_FORMAT_HHMMA)}`}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );

  // Method to render swap card footer(Status and button).
  const renderSwapCardFooter = () => (
    <Row>
      <Col className="d-flex align-items-center justify-content-between">
        <span className="approval">{renderStatus(swapRequests?.swapStatus || 0)}</span>
        <div>
          {!myrequest &&
            swapRequests?.swapStatus === SwapStatus.Pending_Supervisor_Approval &&
            swapRequests?.acceptedUserId === userInfo?.userId &&
            isCurrentTimeIsBeforeShiftStartTime(swapRequests.shiftStartDateTime || '') &&
            isCurrentTimeIsBeforeShiftStartTime(swapRequests.requestedShiftStartDateTime || '') && (
              <Button variant="outline-primary" onClick={() => onSwapButtonClickHandler(SwapActions.Decline)}>
                {Strings.Button.Decline}
              </Button>
            )}
          {!myrequest &&
            swapRequests?.swapStatus === SwapStatus.Pending_Partner_Approval &&
            isCurrentTimeIsBeforeShiftStartTime(swapRequests.shiftStartDateTime || '') &&
            isCurrentTimeIsBeforeShiftStartTime(swapRequests.requestedShiftStartDateTime || '') && (
              <Button variant="primary" onClick={() => onSwapButtonClickHandler(SwapActions.Accept)}>
                {Strings.Button.Accept}
              </Button>
            )}
          {myrequest && (swapRequests?.swapStatus === SwapStatus.Pending_Partner_Approval || swapRequests?.swapStatus === SwapStatus.Partner_Declined) && (
            <Button variant="outline-primary" onClick={() => onSwapButtonClickHandler(SwapActions.Delete)}>
              {Strings.Button.Delete}
            </Button>
          )}
          {myrequest &&
            (swapRequests?.swapStatus === SwapStatus.Pending_Partner_Approval || swapRequests?.swapStatus === SwapStatus.Partner_Declined) &&
            isCurrentTimeIsBeforeShiftStartTime(swapRequests.shiftStartDateTime || '') &&
            isCurrentTimeIsBeforeShiftStartTime(swapRequests.requestedShiftStartDateTime || '') && (
              <Button variant="primary" onClick={() => onSwapButtonClickHandler(SwapActions.Resend)}>
                {Strings.Button.Resend}
              </Button>
            )}
        </div>
      </Col>
    </Row>
  );

  // Method to render confirmation modal popup.
  const renderConfirmationModalPopup = () => {
    let confirmationMessage = '';
    switch (swapActionType) {
      case SwapActions.Accept:
        confirmationMessage = Strings.Swap.Confirmation.AcceptSwap;
        break;
      case SwapActions.Decline:
        confirmationMessage = Strings.Swap.Confirmation.DeclineSwap;
        break;
      case SwapActions.Delete:
        confirmationMessage = Strings.Swap.Confirmation.DeleteSwap;
        break;
      case SwapActions.Resend:
        confirmationMessage = Strings.Swap.Confirmation.ResendSwap;
        break;
      default:
        break;
    }

    return (
      <ConfirmationModal
        show={confirmationModalShow}
        message={confirmationMessage}
        onConfirm={handleConfirm}
        onCancel={handleClose}
        cancelButtonText={Strings.Button.No}
        confirmButtonText={Strings.Button.Yes}
      />
    );
  };

  return (
    <>
      <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main shiftdetails-card swap-card">
        <Card>
          <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
            {renderSwapCardHeaders()}
            <CustomToggle eventKey="0" callback={toggleCollapse}>
              <Arrow />
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="shift-details">
              {renderSwapCardBodyContent()}
              {renderSwapCardFooter()}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {renderConfirmationModalPopup()}
    </>
  );
};
export default SwapCard;
