/**
 * @file   src\components\commonComponents\LeaveCard.tsx
 * @brief  Leave request list card.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, useAccordionButton, Button } from './ThirdPartyComponents';
import { CustomToggleProps, ILeaveCard } from '../interfaces/generalInterface';
import Arrow from '../assests/icons/DownArrow';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import Pending from '../assests/icons/Pending';
import Approved from '../assests/icons/Publish';
import Declined from '../assests/icons/Declined';
import { LeaveRequestStatus, LeaveTypes } from '../utils/enums';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};

const LeaveCardCommon = ({ leaveType, totalLeaveDays, requistID, requestor, contact, startDate, endDate, status, declineReason, handleApprove, handleDecline }: ILeaveCard) => {
  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  // Renders the status text and icon based on the status request status.
  const renderStatus = () => {
    switch (status) {
      case LeaveRequestStatus.Pending_Approval:
        return (
          <>
            {LeaveRequestStatus[LeaveRequestStatus.Pending_Approval].replace(/_/g, ' ')} <Pending />
          </>
        );
      case LeaveRequestStatus.Accepted:
        return (
          <>
            {Strings.Swap.Approved} <Approved />
          </>
        );
      case LeaveRequestStatus.Declined:
        return (
          <>
            {Strings.Swap.Declined} <Declined />
          </>
        );
      default:
        return '';
    }
  };

  // Get leave type based on leave type id
  const getLeaveTypeLabel = (id: number): string => {
    const leaveTypeKey = Object.keys(LeaveTypes).find((key) => LeaveTypes[key as keyof typeof LeaveTypes] === id);
    if (leaveTypeKey) {
      // Convert enum key to string
      return `${leaveTypeKey.replace(/_/g, ' ')} Leave`;
    }
    return '';
  };

  // Method to render leave actions.
  const renderActions = () => {
    if (status === LeaveRequestStatus.Declined) {
      return (
        <>
          {Strings.Label.Reason}:<span className="fw-sb ps-1">{declineReason}</span>
        </>
      );
    }

    if (status === LeaveRequestStatus.Pending_Approval) {
      return (
        <>
          <Button variant="outline-primary" type="submit" onClick={handleDecline}>
            {Strings.Button.Decline}
          </Button>
          <Button variant="primary" type="submit" onClick={handleApprove}>
            {Strings.Button.Approve}
          </Button>
        </>
      );
    }

    return '';
  };

  return (
    <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main leave-card acc-lg-hide">
      <Card>
        <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
          <Col className="d-flex align-items-center">{getLeaveTypeLabel(leaveType)}</Col>
          <div>
            {Strings.Leave.TotalLeaveDays} : {totalLeaveDays}
            <CustomToggle eventKey="0" callback={toggleCollapse}>
              <Arrow />
            </CustomToggle>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="shift-details">
            <Col className="shift-details-sub">
              <Row>
                <Col xl={4} md={6} xs={6}>
                  {Strings.Leave.RequistID}
                  <span>{requistID}</span>
                </Col>
                <Col xl={4} md={6} xs={6}>
                  {Strings.Swap.Requestor}
                  <span>{requestor}</span>
                </Col>
                <Col xl={4} md={6} xs={6}>
                  {Strings.Label.Contact}
                  <span>{contact}</span>
                </Col>
                <Col xl={4} md={6} xs={6}>
                  {Strings.Label.StartDate}
                  <span>{startDate}</span>
                </Col>
                <Col xl={4} md={6} xs={6}>
                  {Strings.Label.EndDate}
                  <span>{endDate}</span>
                </Col>
              </Row>
            </Col>
            <Row className="align-items-center justify-content-between pt-3">
              <Col>
                <span className="pe-1">{Strings.Label.Status}:</span>
                <span className="fw-sb d-inline-block">{renderStatus()}</span>
              </Col>
              <Col xl="auto" lg="auto" xs={12} md="auto" className="m-xs-8">
                {renderActions()}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default LeaveCardCommon;
