/**
 * @file   src\containers\Scheduler\Schedule\CAResource.tsx
 * @brief  CA Resource list page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useEffect } from 'react';
import { Row, Col, Breadcrumb, useState, Button, useParams, moment, useLocation, useNavigate, Modal } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import AssignCACard from '../../../components/AssignCACard';
import EmployeeCard from '../../../components/EmployeeCard';
import KKICheckbox from '../../../components/KKICheckbox';
import { AdminRoutePath, AssignCAType, NavigationToEmployeeCardFrom } from '../../../utils/enums';
import { CURRENT_DATE_FORMAT_WITH_OFFSET, HTTP_STATUS_200, NUMBER_0, NUMBER_1, NUMBER_2, NUMBER_3, NUMBER_4 } from '../../../utils/constants';
import PDFIcon from '../../../assests/icons/PDF';
import { ICADetails } from '../../../interfaces/caInterface';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { checkShiftOverstaffed, exportCallOutList, getCallOnCAList, revertAllCalloutState, sendCallOnMessages } from '../../../store/actions/manageCallOutShiftAction';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader';
import { assignCA, getShiftDetails, revertAllScheduleState } from '../../../store/actions/manageCAScheduleAction';
import { ICA } from '../../../interfaces/scheduleInterface';
import { MessageToaster } from '../../../utils/toastUtils';
import { IcalloutUserInfo } from '../../../interfaces/callOutShiftInterface';
import { AT_LEAST_ONE_EMPLOYEE_REQUIRED, REPLACE_CA_CALLOUT_COUNT_EXCEED } from '../../../messages/validationMessages';
import { isShiftDateISHoliday, revertAllCommonState } from '../../../store/actions/commonAction';
import { IOverlap } from '../../../interfaces/commonInterface';
import { stringFormat } from '../../../utils/helper';

// Toast object creation.
const toast = new MessageToaster();

const CAResource = () => {
  // Create current location object.
  const location = useLocation();

  // Navigate object creation.
  const navigate = useNavigate();

  // Create route param object to access the route parameters.
  const params = useParams();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access location state variables.
  const calloutFilterType = location.state?.calloutFilterType || null;

  // Access redux state variables.
  const {
    isFetchCallOnCALoading,
    callOnCAList,
    isExportCallOutLoading,
    isExportCallOutSuccess,
    exportCallOutErrorCode,
    exportCallOutMessage,
    pdfData,
    isSendCallOnSmsLoading,
    isSendCallOnSmsSuccess,
    sendCallOnSmsErrorCode,
    sendCallOnSmsMessage,
    isCheckOverStaffedLoading,
    isCheckOverStaffedSuccess,
    checkOverStaffedErrorCode,
    checkOverStaffedMessage,
  } = useAppSelector((state: RootState) => state.manageCallOut);
  const { isShiftHolidayLoading, isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, overlapResponse } = useAppSelector((state: RootState) => state.common);
  const { isShiftDetailsLoading, shiftDetails, isAssignShiftLoading, isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage } = useAppSelector(
    (state: RootState) => state.manageCASchedule,
  );

  // Initialize component stat variables.
  const [selectedEmployees, setSelectedEmployees] = useState<ICA[]>([]);
  const [selectedEmployeesWithOverlap, setSelectedEmployeesWithOverlap] = useState<ICA[]>([]);
  const [userInfo, setUserInfo] = useState<IcalloutUserInfo[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);
  const [overStaffedMessage, setOverStaffedMessage] = useState<string>('');
  const [assignButtonPopup, setAssignButtonPopup] = useState<boolean>(false);
  const [holidayConfirmPopup, setHolidayConfirmPopup] = useState<boolean>(false);
  const [overlapPopup, setOverlapPopup] = useState<boolean>(false);
  const [overlapCAs, setOverlapCAs] = useState<ICA[]>([]);

  /* Access and set the location url parameters. */
  const shiftId: number = params.shiftId ? Number(params.shiftId) : NUMBER_0;
  const shiftFromTime: string | null = params.shiftFromTime || null;
  const shiftDate: string | null = params.shiftDate || '';

  // Method to fetch shift details.
  const fetchShiftDetails = () => {
    // Fetch shift details.
    dispatch(getShiftDetails({ shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));
  };

  // Method to check overstaffed shift to get CA list based on callout lgorithm.
  const fetchCABasedOnOverStaffChecking = () => {
    // Check the shift is overstaffed or not.
    if (shiftId) {
      dispatch(checkShiftOverstaffed({ shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));
    }
  };

  // Component initial loading.
  useEffect(() => {
    fetchShiftDetails();
    fetchCABasedOnOverStaffChecking();
    return () => {
      dispatch(revertAllCalloutState());
      dispatch(revertAllScheduleState());
    };
  }, []);

  // Check overstaffed shift api response state change.
  useEffect(() => {
    if (isCheckOverStaffedSuccess && checkOverStaffedErrorCode === HTTP_STATUS_200 && checkOverStaffedMessage) {
      // Fetch callout CA list if request parameters are valid.
      if (shiftId > NUMBER_0 && shiftFromTime && shiftDate) {
        setOverStaffedMessage('');
        dispatch(
          getCallOnCAList({
            shiftId,
            shiftDate: shiftDate !== '-' ? shiftDate : '',
            shiftFromTime: shiftFromTime !== '-' ? shiftFromTime : '',
            currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
            fromShiftAssign: false,
          }),
        );
      }
    } else if (!isCheckOverStaffedSuccess && checkOverStaffedErrorCode > HTTP_STATUS_200 && checkOverStaffedMessage) {
      setSelectedEmployees([]);
      setOverStaffedMessage(checkOverStaffedMessage);
    }
  }, [isCheckOverStaffedSuccess, checkOverStaffedErrorCode, checkOverStaffedMessage]);

  // Assign CA response state change.
  useEffect(() => {
    if (isAssignShiftSuccess && assignShiftErrorCode === HTTP_STATUS_200 && assignShiftMessage) {
      setSelectedEmployees([]);
      setSelectedEmployeesWithOverlap([]);
      toast.toastSuccess(assignShiftMessage);
      fetchShiftDetails();
      fetchCABasedOnOverStaffChecking();
    } else if (!isAssignShiftSuccess && assignShiftErrorCode > HTTP_STATUS_200 && assignShiftMessage) {
      setSelectedEmployees([]);
      setSelectedEmployeesWithOverlap([]);
      fetchShiftDetails();
      fetchCABasedOnOverStaffChecking();
      toast.toastError(assignShiftMessage);
    }
  }, [isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage]);

  // Shift holiday checking api response state change.
  useEffect(() => {
    if (isShiftHolidaySuccess && shiftHolidayErrorCode === HTTP_STATUS_200 && shiftHolidayMessage) {
      const filteredCAs = overlapResponse?.overlap
        ?.filter((res: IOverlap) => res.isOverlap === true)
        ?.map((cas: IOverlap): ICA => ({ caId: cas.caId, caName: cas.caName, ColorCode: cas.colorCode, IsLD: cas.isLD, OnCall: cas.onCall }));
      setOverlapCAs(filteredCAs || []);
      if (overlapResponse?.holiday?.isHoliday) {
        setHolidayConfirmPopup(true);
      } else if (overlapResponse && filteredCAs && filteredCAs.length > NUMBER_0) {
        setOverlapPopup(true);
      } else {
        setAssignButtonPopup(true);
      }
      dispatch(revertAllCommonState());
    } else if (!isShiftHolidaySuccess && shiftHolidayErrorCode > HTTP_STATUS_200 && shiftHolidayMessage) {
      toast.toastError(shiftHolidayMessage);
      dispatch(revertAllCommonState());
    }
  }, [isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, overlapResponse]);

  // Update the "Select All" checkbox state based on individual checkbox states.
  useEffect(() => {
    const allEmployeeIDs = callOnCAList ? callOnCAList.map((ca) => ca.caId) : [];
    const selectedEmployeeIDs = selectedEmployees.map((employee) => employee.caId);
    setIsSelectAllChecked(allEmployeeIDs.length > 0 && allEmployeeIDs.length === selectedEmployeeIDs.length);
  }, [selectedEmployees]);

  // Notify CA regarding callout shift api response state change.
  useEffect(() => {
    if (isSendCallOnSmsSuccess && sendCallOnSmsErrorCode === HTTP_STATUS_200 && sendCallOnSmsMessage) {
      toast.toastSuccess(sendCallOnSmsMessage);
      setSelectedEmployees([]);
      setUserInfo([]);
    } else if (!isSendCallOnSmsSuccess && sendCallOnSmsErrorCode > HTTP_STATUS_200 && sendCallOnSmsMessage) {
      toast.toastError(sendCallOnSmsMessage);
    }
  }, [isSendCallOnSmsSuccess, sendCallOnSmsErrorCode, sendCallOnSmsMessage]);

  // Export pdf api response state change handling.
  useEffect(() => {
    if (isExportCallOutSuccess && pdfData) {
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link element, set download attributes, and trigger a click
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'CallOut-CA-List.pdf'); // Set the PDF filename
      document.body.appendChild(link);
      link.click();
      toast.toastSuccess(Strings.Text.CalloutCAExportSuccessText);
      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
      dispatch(revertAllCalloutState());
    } else if (!isExportCallOutSuccess && exportCallOutErrorCode > HTTP_STATUS_200 && exportCallOutMessage) {
      toast.toastError(exportCallOutMessage);
      dispatch(revertAllCalloutState());
    }
  }, [isExportCallOutSuccess, exportCallOutErrorCode, exportCallOutMessage, pdfData]);

  // Handles the checkbox change event.
  const handleCheckboxChange = (isChecked: boolean, caDetails: ICADetails) => {
    const { caId, phone, colorGroup, lightDuty } = caDetails;
    if (isChecked) {
      setSelectedEmployees((prevSelected) => [
        ...prevSelected,
        {
          caId,
          ColorCode: Number(colorGroup),
          OnCall: false,
          IsLD: lightDuty ?? false,
        },
      ]);
      setSelectedEmployeesWithOverlap((prevSelected) => [
        ...prevSelected,
        {
          caId,
          ColorCode: Number(colorGroup),
          OnCall: false,
          IsLD: lightDuty ?? false,
        },
      ]);
      if (phone && !userInfo.some((item: IcalloutUserInfo) => item.user === caId)) {
        setUserInfo((info) => [...info, { user: caId, phone }]);
      }
    } else {
      setSelectedEmployees((prevSelected) => prevSelected.filter((entry) => entry.caId !== caId));
      setSelectedEmployeesWithOverlap((prevSelected) => prevSelected.filter((entry) => entry.caId !== caId));
      setUserInfo((info) => info.filter((user) => user.user !== caId));
    }
  };

  //  Handles the "Send Message" button click event. Shows an error if no employees are selected.
  const handleSendMessage = (): void => {
    if (selectedEmployees.length > 0) {
      dispatch(
        sendCallOnMessages({
          shiftId,
          callOutType: shiftDetails?.callOutType || NUMBER_0,
          users: userInfo,
        }),
      );
    } else {
      toast.toastError(Strings.Text.CallOutNotifyCheckBoxValidationText);
    }
  };

  // Handle the "Export as PDF" button click event.
  const handleExportPDFClick = (): void => {
    if (shiftId > NUMBER_0 && shiftFromTime && shiftDate) {
      dispatch(
        exportCallOutList({
          shiftId,
          shiftDate: shiftDate !== '-' ? shiftDate : '',
          shiftFromTime: shiftFromTime !== '-' ? shiftFromTime : '',
          currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
        }),
      );
    }
  };

  // Handle the "Select All" checkbox change event.
  const handleSelectAllChange = (isChecked: boolean) => {
    if (isChecked) {
      const allEmployeeIDs = callOnCAList ? callOnCAList.map((ca): ICA => ({ caId: ca.caId, ColorCode: ca.colorGroup, IsLD: ca.lightDuty, OnCall: ca.isOnCall })) : [];
      const allUsers = callOnCAList ? callOnCAList.map((ca): IcalloutUserInfo => ({ user: ca.caId, phone: ca.phone || '' })) : [];
      setSelectedEmployees(allEmployeeIDs);
      setSelectedEmployeesWithOverlap(allEmployeeIDs);
      setUserInfo(allUsers);
    } else {
      setSelectedEmployees([]);
      setSelectedEmployeesWithOverlap([]);
    }
    setIsSelectAllChecked(isChecked);
  };

  //  Handles the "Assign" button click event. Shows an error if no employees are selected.
  const handleAssignClick = (): void => {
    if (selectedEmployees.length === 0) {
      toast.toastError(AT_LEAST_ONE_EMPLOYEE_REQUIRED);
      return;
    }
    if (shiftDetails?.pendingCallOuts && shiftDetails.pendingCallOuts < selectedEmployees.length) {
      toast.toastError(stringFormat(REPLACE_CA_CALLOUT_COUNT_EXCEED, shiftDetails.pendingCallOuts));
      return;
    }
    if (shiftDetails?.startDate) {
      const caIds = selectedEmployees.map((emp: ICA) => emp.caId);
      dispatch(isShiftDateISHoliday({ holidayDate: shiftDetails?.startDate?.split('T')[0] || '', shiftId: shiftDetails?.shiftId || NUMBER_0, caId: caIds }));
    } else {
      setAssignButtonPopup(true);
    }
  };

  // Method to assign CAs to the shift.
  const assignCAs = (caInfo: ICA[]) => {
    if (shiftDetails) {
      const assignRequest = {
        Cas: caInfo,
        shiftId: shiftDetails?.shiftId,
        shiftStart: shiftDetails?.shiftFromTime,
        currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
        overrideColorCode: true,
        replace: true,
      };
      dispatch(assignCA(assignRequest));
      setAssignButtonPopup(false);
      setHolidayConfirmPopup(false);
      setOverlapPopup(false);
    }
  };

  // Handles the assign confirmation
  const onAssignConfirm = (assignmentType: number) => {
    if (assignmentType === AssignCAType.HOLIDAY) {
      if (overlapCAs && overlapCAs.length > NUMBER_0) {
        setHolidayConfirmPopup(false);
        setOverlapPopup(true);
      } else {
        assignCAs(selectedEmployees);
      }
    } else {
      assignCAs(selectedEmployees);
    }
  };

  // Close overlap popup event handler.
  const onOverlapPopupClose = () => setOverlapPopup(false);

  // Overlapped CA selection assignment handler.
  const onOverlapAssignmentHandler = () => assignCAs(selectedEmployeesWithOverlap);

  // Handler for overlap checkbox change
  const handleOverlapCheckboxChange = (overlapCaDetails: ICA) => {
    setSelectedEmployeesWithOverlap((prev) => {
      if (prev.some((cas: ICA) => cas.caId === overlapCaDetails.caId)) {
        // If caId is already selected, remove it from the array
        return prev.filter((ca: ICA) => ca.caId !== overlapCaDetails.caId);
      } else {
        // If caId is not selected, add it to the array
        return [...prev, overlapCaDetails];
      }
    });
  };

  // Bind callout category.
  const bindCallOutCategory = (category: number) => {
    let categoryString: string | null = null;
    switch (category) {
      case NUMBER_1:
        categoryString = Strings.CalloutCategory.MorThanAShiftAway;
        break;
      case NUMBER_2:
        categoryString = Strings.CalloutCategory.LessThanAShiftAway;
        break;
      case NUMBER_3:
        categoryString = Strings.CalloutCategory.ninetyminAway;
        break;
      case NUMBER_4:
        categoryString = Strings.CalloutCategory.sixtyminAway;
        break;
      default:
        categoryString = Strings.CalloutCategory.MorThanAShiftAway;
        break;
    }
    return categoryString ? `(${categoryString})` : '';
  };

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate({ pathname: AdminRoutePath.SCHCallOut }, { state: { calloutFilterType } })}>{Strings.HD.ManageCallOutShifts}</Breadcrumb.Item>
          <Breadcrumb.Item active>{Strings.Button.CAList}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="pageheader">
        <Row className="align-items-center">
          <Col>
            <h1>
              {Strings.HD.CAResourceOptions} <small>{bindCallOutCategory(shiftDetails?.callOutType || 0)}</small>
            </h1>
          </Col>
        </Row>
      </div>
      <AssignCACard caresource shiftDetails={shiftDetails} isLd={false} />
      <div className="mt-4">
        <h2>{shiftDetails?.callOutType === NUMBER_1 || shiftDetails?.callOutType === NUMBER_2 ? Strings.HD.CategoryAList : Strings.HD.CategoryBList}</h2>
      </div>
      <div className="filter-main mt-3">
        {callOnCAList && callOnCAList.length > 0 && (
          <Row className="justify-content-between align-items-center">
            <Col xs="auto">
              <KKICheckbox
                id="p1"
                key={2}
                name="publishStatus"
                checkBoxType="checkbox"
                label={Strings.Label.SelectAllCA}
                checked={isSelectAllChecked}
                onChange={(e: any) => handleSelectAllChange(e.target.checked)}
              />
            </Col>
            <Col sm="auto" xs={12} className="button-container-right text-end m-xs-16">
              <Button variant="outline-primary" type="submit" onClick={() => handleAssignClick()}>
                {Strings.Button.Assign}
              </Button>
              <Button variant="outline-primary" type="submit" onClick={handleExportPDFClick}>
                <PDFIcon />
                {Strings.Button.ExportasPDF}
              </Button>
              <Button variant="primary" type="button" onClick={handleSendMessage}>
                {Strings.Button.SendMessage}
              </Button>
            </Col>
          </Row>
        )}
        {overStaffedMessage && (
          <Row className="justify-content-between align-items-center">
            <Col className="text-center">{overStaffedMessage}</Col>
          </Row>
        )}
        {!overStaffedMessage && (!callOnCAList || (callOnCAList && callOnCAList.length === 0)) && (
          <Row className="justify-content-between align-items-center">
            <Col className="text-center">{Strings.Text.NoCAFound}</Col>
          </Row>
        )}
      </div>
      <Row>
        {callOnCAList &&
          callOnCAList.length > 0 &&
          callOnCAList.map((ca: ICADetails) => (
            <Col key={ca.caId} xl={6} lg={6} md={12}>
              <EmployeeCard
                caDetails={ca}
                shiftDetails={shiftDetails}
                isFrom={NavigationToEmployeeCardFrom.Callout_Page}
                selectedEmployees={selectedEmployees}
                isSelectAllChecked={isSelectAllChecked}
                onCheckboxChange={handleCheckboxChange}
              />
            </Col>
          ))}
      </Row>
      <Modal show={assignButtonPopup} onHide={() => setAssignButtonPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.AssignCAsPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setAssignButtonPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onAssignConfirm(AssignCAType.ASSIGNMENT)} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={holidayConfirmPopup} onHide={() => setHolidayConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.HolidayConfirmPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setHolidayConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onAssignConfirm(AssignCAType.HOLIDAY)} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={overlapPopup} onHide={() => onOverlapPopupClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.OverlapPop.Heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Strings.OverlapPop.Text1}</p>
          {overlapCAs.map((overlapItem: ICA) => (
            <div className="mb-2 fw-sb" key={overlapItem.caId}>
              <KKICheckbox
                id="overlap"
                name="overlap"
                checkBoxType="checkbox"
                label={overlapItem.caName || ''}
                checked={selectedEmployeesWithOverlap.some((cas: ICA) => cas.caId === overlapItem.caId)}
                onChange={() => handleOverlapCheckboxChange(overlapItem)}
              />
            </div>
          ))}
          <p>
            <span className="fw-sb">{Strings.OverlapPop.Note}</span> {Strings.OverlapPop.NoteText}
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button variant="secondary" onClick={() => onOverlapPopupClose()} className="w-auto">
            {Strings.Button.Cancel}
          </Button>
          <Button variant="primary" onClick={() => onOverlapAssignmentHandler()}>
            {Strings.Button.Proceed}
          </Button>
        </Modal.Footer>
      </Modal>
      {(isShiftDetailsLoading ||
        isFetchCallOnCALoading ||
        isExportCallOutLoading ||
        isSendCallOnSmsLoading ||
        isCheckOverStaffedLoading ||
        isAssignShiftLoading ||
        isShiftHolidayLoading) && <Loader />}
    </div>
  );
};
export default CAResource;
