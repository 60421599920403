/**
 * @file   src\components\SchedulerCard.tsx
 * @brief  Component for listing CA schedules.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from '../assests/strings/Strings.json';
import '../styles/RosterCard.scss';
import { IGroupedScheduleItem, IScheduleItem } from '../interfaces/scheduleInterface';
import { Col, Dropdown, useNavigate, moment } from './ThirdPartyComponents';
import More from '../assests/icons/MobileMenu';
import DownFill from '../assests/icons/DownFill';
import { DATE_FORMAT_DD_MMM_HHMMA, DATE_FORMAT_HHMMA, NIGHT_SHIFTS, NUMBER_0 } from '../utils/constants';
import { LeaveStatus, LeaveTypes, MyScheduleActions, ScheduleTabs, AdminRoutePath, NoShow } from '../utils/enums';
import { ILeaveDetails } from '../interfaces/leaveInterface';
import { isCurrentTimeIsBeforeShiftStartTime } from '../utils/helper';

const SchedulerCard = ({ shifts, leaves, holidays, onButtonClick, caId = NUMBER_0 }: IGroupedScheduleItem) => {
  // Navigate object creation.
  const navigate = useNavigate();

  return (
    <div>
      {shifts.length > 0 &&
        shifts.map((item: IScheduleItem, index: number) => (
          <Col
            key={`${item.shiftId}-${index}`}
            className={`roster-shift d-flex align-items-center 
                ${item.published ? 'publishedshift' : 'draftshift'} ${item.isNoShow && item.isNoShow > NoShow.Show ? 'noshow' : ''} ${item.isOnCall && 'oncallshift'}`}
          >
            <div className="w-100">
              <div className="shift-name">
                {item.shiftName} ({item.shortName})
              </div>
              <div className="ca-count d-flex">
                {Strings.Shift.CA}: {item.totalAvailed}/{item.totalCARequired}
                <div className="colorgroup">
                  {item.yellowRequired > NUMBER_0 && <span className="colorcode yellow" />}
                  {item.blueRequired > NUMBER_0 && <span className="colorcode blue" />}
                  {item.redRequired > NUMBER_0 && <span className="colorcode red" />}
                  {item.whiteRequired > NUMBER_0 && <span className="colorcode white" />}
                </div>
              </div>
              <div className="ca-count">
                {item.lightDutyRequired > NUMBER_0 ? (
                  <span className="lateduty">
                    {Strings.Shift.LD}: {item.lightDutyCount}/{item.lightDutyRequired}
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className="shift-time pe-3">
                {NIGHT_SHIFTS.includes(item.shortName)
                  ? `${moment.utc(item.shiftFromTime).format(DATE_FORMAT_DD_MMM_HHMMA)} - ${moment.utc(item.shiftToTime).format(DATE_FORMAT_DD_MMM_HHMMA)}`
                  : `${moment.utc(item.shiftFromTime).format(DATE_FORMAT_HHMMA)} - ${moment.utc(item.shiftToTime).format(DATE_FORMAT_HHMMA)}`}
              </div>
              {item.onCallRequired > 0 && (
                <span className="oncall-label d-flex align-items-center">
                  {Strings.Label.OnCall}: {`${item.onCallTotal}/${item.onCallRequired}`}
                </span>
              )}
            </div>
            <Dropdown align="end">
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <More />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href={void 0}
                  onClick={() =>
                    navigate(
                      AdminRoutePath.SCHShiftDetailsByCAId.replace(':shiftId', item.shiftId.toString())
                        .replace(':type', ScheduleTabs.MySchedule.toString())
                        .replace(':caId', caId?.toString()),
                    )
                  }
                >
                  {Strings.Shift.Details}
                </Dropdown.Item>
                <Dropdown.Item href={void 0} onClick={() => onButtonClick(item.shiftId || 0, item.shiftFromTime || '', MyScheduleActions.Unassign)}>
                  {Strings.Shift.UnAssign}
                </Dropdown.Item>
                {!item.published && (
                  <Dropdown.Item href={void 0} onClick={() => onButtonClick(item.shiftId || 0, item.shiftFromTime || '', MyScheduleActions.Publish)}>
                    {Strings.Shift.Publish}
                  </Dropdown.Item>
                )}
                {item.published && (
                  <Dropdown.Item href={void 0} onClick={() => onButtonClick(item.shiftId || 0, item.shiftFromTime || '', MyScheduleActions.Unpublish)}>
                    {Strings.Button.UnPublish}
                  </Dropdown.Item>
                )}
                {item.published && isCurrentTimeIsBeforeShiftStartTime(item.shiftToTime || '') && (
                  <Dropdown.Item href={void 0} onClick={() => onButtonClick(item.shiftId || 0, item.shiftFromTime || '', MyScheduleActions.Callout)}>
                    {Strings.Shift.CallOut}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            {NIGHT_SHIFTS.includes(item.shortName) && (
              <div className={`shift-arrow${item.isNextDayNightShift ? ' next-day' : ''}`}>
                <DownFill />
              </div>
            )}
          </Col>
        ))}
      {leaves.length > 0 &&
        leaves.map((leave: ILeaveDetails) => (
          <Col
            key={leave.leaveRequestId}
            className={`roster-shift d-flex align-items-center leave
                    ${leave.leaveStatus === LeaveStatus.Approved ? 'publishedshift' : 'draftshift'}`}
          >
            <div className="w-100">
              <div className="fw-md text-center">
                {Strings.Leave.Leave}
                <span> ({LeaveTypes[leave.leaveType || 1].replace(/_/g, ' ')})</span>
              </div>
            </div>
            <Dropdown align="end">
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <More />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={void 0} onClick={() => onButtonClick(leave.leaveRequestId || 0, '', MyScheduleActions.LeaveDetails)}>
                  {Strings.Shift.Details}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        ))}
      {holidays.length > 0 && (
        <Col className="roster-shift d-flex align-items-center holiday">
          <div className="w-100">
            <div className="fw-md text-center">{Strings.Label.Holiday}</div>
          </div>
        </Col>
      )}
    </div>
  );
};
export default SchedulerCard;
