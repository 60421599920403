/**
 * @file   src\store\slices\manageCASlice.ts
 * @brief  This file is responsible for creating CA user management slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IManageCA } from '../../interfaces/caInterface';
import { activateOrDeactivateCA, getAllCAUsers, getCADetails, updateCADetails, revertAllManageCAState, resetMOTPosition } from '../actions/manageCAAction';

// Initial state.
const manageCAState: IManageCA = {
  isFetchCASuccess: false,
  isFetchCALoading: false,
  fetchCAErrorCode: 0,
  fetchCAMessage: null,
  caList: [],
  caData: [],
  total: 0,
  limit: 0,
  profileInfo: null,
  isUpdateCAStatusSuccess: false,
  isUpdateCAStatusLoading: false,
  updateCAStatusErrorCode: 0,
  updateCAStatusMessage: null,
  isFetchCADetailsSuccess: false,
  isFetchCADetailsLoading: false,
  fetchCADetailsErrorCode: 0,
  fetchCADetailsMessage: null,
  caDetails: null,
  isUpdateCADetailsSuccess: false,
  isUpdateCADetailsLoading: false,
  updateCADetailsErrorCode: 0,
  updateCADetailsMessage: null,
  isResetMOTPositionSuccess: false,
  isResetMOTPositionLoading: false,
  resetMOTPositionErrorCode: 0,
  resetMOTPositionMessage: null,
};

// leave management slice.
export const manageCASlice = createSlice({
  name: 'manageCA',
  initialState: manageCAState,
  reducers: {
    resetCAState: () => manageCAState,
  },
  extraReducers(builder) {
    // ********************* Start of all CA listing action state handling. *********************
    // Get all CA default state.
    builder.addCase(getAllCAUsers.pending, (state) => {
      state.isFetchCASuccess = false;
      state.isFetchCALoading = true;
      state.fetchCAErrorCode = 0;
      state.fetchCAMessage = '';
    });
    //  Get all CA success state.
    builder.addCase(getAllCAUsers.fulfilled, (state, action) => {
      const { status, info } = action.payload;
      const error = action.payload?.error || 0;
      state.isFetchCALoading = false;
      state.isFetchCASuccess = true;
      state.fetchCAErrorCode = error;
      state.fetchCAMessage = status || '';
      state.caData = info?.cAList || [];
      state.total = info?.total || 0;
      state.limit = info?.limit || 0;
    });
    //  Get all CA error state.
    builder.addCase(getAllCAUsers.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchCASuccess = false;
      state.isFetchCALoading = false;
      state.fetchCAErrorCode = errcode;
      state.fetchCAMessage = error || '';
    });
    // ********************* End of all CA listing action state handling. *********************

    // ********************* Start of CA status update action state handling. *********************
    // Update CA status default state.
    builder.addCase(activateOrDeactivateCA.pending, (state) => {
      state.isUpdateCAStatusSuccess = false;
      state.isUpdateCAStatusLoading = true;
      state.updateCAStatusErrorCode = 0;
      state.updateCAStatusMessage = '';
    });
    //  Update CA status success state.
    builder.addCase(activateOrDeactivateCA.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isUpdateCAStatusLoading = false;
      state.isUpdateCAStatusSuccess = true;
      state.updateCAStatusErrorCode = code;
      state.updateCAStatusMessage = status || '';
    });
    // Update CA status error state.
    builder.addCase(activateOrDeactivateCA.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isUpdateCAStatusSuccess = false;
      state.isUpdateCAStatusLoading = false;
      state.updateCAStatusErrorCode = errcode;
      state.updateCAStatusMessage = error || '';
    });
    // ********************* End of CA status update action state handling. *********************

    // ********************* Start of get CA details action state handling. *********************
    // Get CA details default state.
    builder.addCase(getCADetails.pending, (state) => {
      state.isFetchCADetailsSuccess = false;
      state.isFetchCADetailsLoading = true;
      state.fetchCADetailsErrorCode = 0;
      state.fetchCADetailsMessage = '';
    });
    //  Get CA details success state.
    builder.addCase(getCADetails.fulfilled, (state, action) => {
      const { status, info } = action.payload;
      const error = action.payload?.error || 0;
      state.isFetchCADetailsLoading = false;
      state.isFetchCADetailsSuccess = true;
      state.fetchCADetailsErrorCode = error;
      state.fetchCADetailsMessage = status || '';
      state.profileInfo = info;
    });
    //  Get CA details error state.
    builder.addCase(getCADetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isFetchCADetailsSuccess = false;
      state.isFetchCADetailsLoading = false;
      state.fetchCADetailsErrorCode = error;
      state.fetchCADetailsMessage = error || '';
    });
    // ********************* End of get CA details action state handling. *********************

    // ********************* Start of update CA details action state handling. *********************
    // Update CA details default state.
    builder.addCase(updateCADetails.pending, (state) => {
      state.isUpdateCADetailsSuccess = false;
      state.isUpdateCADetailsLoading = true;
      state.updateCADetailsErrorCode = 0;
      state.updateCADetailsMessage = '';
    });
    //  Update CA details success state.
    builder.addCase(updateCADetails.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isUpdateCADetailsLoading = false;
      state.isUpdateCADetailsSuccess = true;
      state.updateCADetailsErrorCode = code;
      state.updateCADetailsMessage = status || '';
    });
    // Update CA details error state.
    builder.addCase(updateCADetails.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isUpdateCADetailsSuccess = false;
      state.isUpdateCADetailsLoading = false;
      state.updateCADetailsErrorCode = errcode;
      state.updateCADetailsMessage = error || '';
    });
    // ********************* End of update CA details action state handling. *********************

    // ********************* Start of reset MOT position action state handling. *********************
    // Reset MOT position default state.
    builder.addCase(resetMOTPosition.pending, (state) => {
      state.isResetMOTPositionSuccess = false;
      state.isResetMOTPositionLoading = true;
      state.resetMOTPositionErrorCode = 0;
      state.resetMOTPositionMessage = '';
    });
    //  Reset MOT position success state.
    builder.addCase(resetMOTPosition.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isResetMOTPositionLoading = false;
      state.isResetMOTPositionSuccess = true;
      state.resetMOTPositionErrorCode = code;
      state.resetMOTPositionMessage = status || '';
    });
    // Reset MOT position error state.
    builder.addCase(resetMOTPosition.rejected, (state, action: any) => {
      const { errcode, error } = action.payload?.error || 0;
      state.isResetMOTPositionSuccess = false;
      state.isResetMOTPositionLoading = false;
      state.resetMOTPositionErrorCode = errcode;
      state.resetMOTPositionMessage = error || '';
    });
    // ********************* End of reset MOT position action state handling. *********************

    // ********************* Clear all manage CA states to initial sate action. *********************
    builder.addCase(revertAllManageCAState, () => manageCAState);
  },
});

// Export manage CA actions.
export const { resetCAState } = manageCASlice.actions;
// Export reducer.
export default manageCASlice.reducer;
